import React, { Component } from 'react';
import { Card, Button, Form, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../Helpers/fetcher.js";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Notify } from "../../Helpers/notification.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class CallDetailRecords extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cdrList: [],
            mobileNumber: '',
            apiPath: props.app.apiPath,
            role: props.app.role,
            userId: props.app.userId,
            noDataDisplayMsg: '',
            startDate: '',
            endDate: '',
        }
        document.title = 'Call Detail Records'
    }

    setvalue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    searchCdr = async (e) => {
        e.preventDefault();
        let sDate = "";
        let eDate ="";
        if(this.state.startDate === ""){
                 sDate = this.state.startDate
        }else{
            sDate =  this.formatDate(this.state.startDate)
        }
        if(this.state.endDate === ""){
                 eDate = this.state.endDate
        }else{
            eDate =  this.formatDate(this.state.endDate)
        }
        if (new Date(sDate) > new Date(eDate)) {
			Notify.notify("Start date cannot be greater than end date.");
			return;
		}
        const data = {
            bni: this.state.mobileNumber,
            pageNo: 1,
            pageSize: 0,
            startDate : sDate,
            endDate : eDate,
        }
        if(this.state.role == "ROLE_SADMIN"){
            data.userType = 'sadmin';
        }else{
            data.userType = 'user';
            data.userId = this.state.userId
        }

        return Fetcher.post(`${this.state.apiPath}/api/obd/searchNumber`, { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
            .then(res => {
                this.setState({'cdrList': res.response});
                if(res.response.length == 0) this.setState({noDataDisplayMsg: 'No records found'});
            })
            .catch(err => {
                console.log('Error in fetching call detail records: ', err);
                Notify.notify('Error in fetching call detail records');
            });
    }

    handleStartDateChange = (date) => {
		this.setState({
			startDate: date,
	    });
    };

    handleEndDateChange = (date) => {
    		this.setState({
    			endDate: date,
    	 });
    };

    formatDate = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
	
		return `${year}-${month}-${day}`;
	};

    render() {

        const cdrList = this.state.cdrList;
        const columns = [
            {
				dataField: 'campId',
				text: 'Campaign Id',
				sort: true,
				hidden: false
			},
            {
                dataField: 'cli',
                text: 'CLI',
                sort: false,
                hidden: false
            },
            {
                dataField: 'username',
                text: 'Username',
                sort: false,
                hidden: false
            },
            {
                dataField: 'start_time',
                text: 'Start Date',
                sort: false,
                hidden: false
            },
            {
                dataField: 'answer_time',
                text: 'Answer Date',
                sort: false,
                hidden: false
            },
            {
                dataField: 'end_time',
                text: 'End Date',
                sort: false,
                hidden: false
            }, {
                dataField: 'answerStatus',
                text: 'Answer Status',
                sort: false,
                hidden: false
            },
            {
                dataField: 'billingduration',
                text: 'Billing Duration',
                sort: false,
                hidden: false
            }];

        const records = Array.isArray(cdrList) ? cdrList.map((row, index) => {
            return {
                campId: row.campId,
                cli: row.cli,
                username: row.username,
                start_time: row.startTime,
                answer_time: row.answerTime,
                end_time: row.endTime,
                answerStatus: row.answerStatus,
                billingduration: row.billingduration
            };
        }) : []

        const { SearchBar, ClearSearchButton } = Search;

        const defaultSorted = [{
            dataField: 'reqDate',
            order: 'desc'
        }];

        const paginationOption = {
            custom: true,
            totalSize: records.length,
            sizePerPage: 10,
            sizePerPageList: [10, 25, 50, 100]
        };

        return (
            <Card>
                <Card.Header>Call Detail Records</Card.Header>
                <Card.Body>
                    <div className="widget-list-table-cont">

                        <Form className="add-campaign-form" method='post' onSubmit={(e) => this.searchCdr(e)}>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group className="form-group col-md-6 float-left">
                                        <Form.Control required name="mobileNumber" type="number" placeholder="Mobile Number" onChange={e => this.setvalue(e)} value={this.state.mobileNumber} />
                                    </Form.Group>
                                    {/* <Button variant="primary" type="submit">Search</Button> */}
                                </Col>
                                <Col sm={3}>
                                    <Form.Group className="form-group col-md-6 float-left">
					                    <DatePicker
						                    className="form-control"
						                    dateFormat="yyyy-MM-dd"
                                            selected={this.state.startDate}
                                            onChange={this.handleStartDateChange}
                                            placeholderText="Start Date"
					                    />
				                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Group className="form-group col-md-6 float-left">
					                    <DatePicker
						                    className="form-control"
						                    dateFormat="yyyy-MM-dd"
						                    selected={this.state.endDate}
                                            onChange={this.handleEndDateChange}
                                            placeholderText="End Date"
					                    />
				                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Button variant="primary" type="submit">Search</Button>
                                </Col>
                            </Row>
                        </Form>
                        <br/>

                        <PaginationProvider pagination={paginationFactory(paginationOption)}>
                            {({
                                paginationProps, paginationTableProps
                            }) => (
                                <ToolkitProvider
                                    keyField="reqDate"
                                    data={records}
                                    columns={columns}
                                    search
                                    bootstrap4
                                >
                                    {
                                        props => (
                                            <div className="">
                                                Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
                                                <span className="search-cont"><SearchBar {...props.searchProps} /></span>
                                                <ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
                                                <Button id="refresh" type="button" className="float-right btn btn-default btn-primary ml-2" onClick={() => this.refreshPage()}>Refresh</Button>
                                                <hr />
                                                <BootstrapTable defaultSorted={defaultSorted} bordered={true} striped={true} condensed wrapperClasses="table-responsive" classes="widgetlist-table" {...props.baseProps} {...paginationTableProps} noDataIndication={this.state.noDataDisplayMsg} />
                                                <PaginationListStandalone {...paginationProps} />
                                            </div>
                                        )}
                                </ToolkitProvider>
                            )}
                        </PaginationProvider>
                    </div>

                </Card.Body>
            </Card>

        );
    }
}

export default CallDetailRecords;