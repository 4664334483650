import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal, Tabs, Tab } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class CloneVoiceCampaign extends Component {
	constructor(props) {
		super(props);

		this.baseFileInput = React.createRef();
		let sitePath = '';
		let filePath = '';

		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);

		this.state = {
			campaignName: '',
			baseName: '',
			patchList: '',
			templateId: '0',
			dtmf: '',
			agentNumber: '',
			agentDtmf: '',
			baseFileType: 'oldBaseFile',
			sendLater: false,
			sendDate: new Date(),
			baseFileInput: this.baseFileInput,
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			contactList: '',
			oldBaseId: '',
			promptId: '',
			welcomeId: '',
			oldWelcomeId: '',
			welcomePromptName: '',
			menuPromptName: '',
			noinputPromptName: '',
			wronginputPromptName: '',
			thanksPromptName: '',
			menuId: '',
			oldMenuId: '',
			menuPromptName: '',
			noinputId: '',
			oldNoinputId: '',
			wronginputId: '',
			oldWronginputId: '',
			thanksId: '',
			oldThanksId: '',
			senderId: '',
			baseUploads: [],
			promptUploads: [],
			senderIdList: [],
			redirectToSummary: false,
			campaignCreated: false,
			sendSmsSuccess: false,
			sendSmsFail: false,
			sendSmsDtmf: false,
			prompt: true,
			welcomePrompt: false,
			menuPrompt: true,
			noinputPrompt: false,
			wronginputPrompt: false,
			thanksPrompt: false,
			sendLink: false,
			smsLink: '',
			smsApiSuccess: '',
			smsSuccessMessage: '',
			smsApiFail: '',
			smsFailMessage: '',
			smsApiDtmf: '',
			smsDtmfMessage: '',
			callDurationSMS: 0,
			trunk: '',
			channels: '',
			agentRows: [],
			channelsList: [],
			menuWaitTime: '',
			oldMenuWaitTime: '',
			rePrompt: '',
			oldRePrompt: '',
			campaignInfo: [],
			campaignPrompts: [],
			campaignAgents: [],
			campaignSms: [],
			baseId: '',
			oldBaseName: '',
			validDtmf: '',
			ivrList: [],
			oldIvrList: [],
			oldIvrId: '',
			ivrId: '',
			ivrName: '',
			oldIvrName: '',
			retryInterval: '',
			oldRetryInterval: '',
			retries: '',
			oldRetries: ''
		}

	}

	componentDidMount() {
		const campaignId = this.props.campaignId;
		const userId = this.state.userId;
		const apiPath = this.state.apiPath;

		// Get Campaign Details
		Fetcher.get(apiPath + '/api/obd/campaign/clone/' + campaignId)
			.then(res => {
				this.setState({
					campaignName: res.campaign.campaignName,
					validDtmf: res.campaign.dtmf,
					templateId: res.campaign.templateId,
					oldBaseId: res.campaign.baseId,
					oldBaseName: res.campaign.baseName,
					channels: res.campaign.channels,
					oldMenuWaitTime: res.campaign.menuWaitTime,
					oldRePrompt: res.campaign.invalidCount,
					campaignPrompts: res.campaignPrompts,
					agentRows: res.campaignAgents,
					campaignSms: res.campaignSms,
					oldIvrList: res.ivrList,
					oldRetryInterval: res.campaign.retryInterval,
					oldRetries: res.campaign.retries
				})
				if (!isEmpty(this.state.campaignSms) && (!('error' in this.state.campaignSms))) {
					this.state.campaignSms.map((fileObj) => {
						if (fileObj.smsEvent === 'CALL_CONNECTED') {
							this.state.smsApiSuccess = fileObj.smsText;
							this.state.smsSuccessMessage = fileObj.smsMessage;
							this.state.callDurationSMS = fileObj.callDurationSMS;
						}
						else if (fileObj.smsEvent === 'VALID_DTMF') {
							this.state.smsApiDtmf = fileObj.smsText;
							this.state.smsDtmfMessage = fileObj.smsMessage;
						}
						else if (fileObj.smsEvent === 'CALL_FAILED') {
							this.state.smsApiFail = fileObj.smsText;
							this.state.smsFailMessage = fileObj.smsMessage;
						}
					})
				}
			})
			.catch(err => { console.log('Error in fetching Campaign Details', err) });

		// Get BaseUpload Files List
		Fetcher.get(apiPath + '/api/obd/base/' + userId)
			.then(res => {
				this.setState({
					baseUploads: res
				})
			})
			.catch(err => { console.log('Error in fetching BaseUpload Files', err) });

		// Get PromptUpload Files List
		Fetcher.get(apiPath + '/api/obd/prompts/' + userId)
			.then(res => {
				this.setState({
					promptUploads: res
				})
			})
			.catch(err => { console.log('Error in fetching PromptUpload Files', err) });

		// Get IVR List
		Fetcher.get(apiPath + '/api/obd/ivr/' + userId)
			.then(res => {
				this.setState({
					ivrList: res
				})
			})
			.catch(err => { console.log('Error in fetching IVR List', err) });

		// Get Sender Id List
		Fetcher.get(apiPath + '/api/obd/cli/user/' + userId)
			.then(res => {
				this.setState({
					senderIdList: res
				})
			})
			.catch(err => { console.log('Error in fetching Sender Id\'s', err) });

		// Get Channels
		Fetcher.get(apiPath + '/api/obd/channels/' + userId)
			.then(res => {
				var slots = res.channels / 20;
				var channelList = [];
				for (let i = 1; i <= slots; i++) {
					const item = {
						slot: i * 20
					};
					channelList.push(item);
				}
				this.setState({
					channelsList: channelList
				})
			})
			.catch(err => { console.log('Error in fetching Channels', err) });
	}

	sendLaterDateChange = date => {
		this.setState({
			sendDate: date
		});
	};
	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}

	handleSelect = function (selectedItems) {
		const dtmfSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			dtmfSelected.push(selectedItems[i].value);
		}
		this.setState({
			dtmf: dtmfSelected.toString()
		});
	}

	handleChange = idx => e => {
		const { name, value } = e.target;
		const agentRows = [...this.state.agentRows];
		agentRows[idx] = {
			[name]: value
		};
		this.setState({
			agentRows
		});
	};
	handleAddRow = () => {
		if (this.state.agentNumber !== '' && this.state.agentDtmf !== '') {
			
			const item = {
				agentNumber: this.state.agentNumber,
				agentDtmf: this.state.agentDtmf
			};
			const checkDtmf = obj => obj.agentDtmf === this.state.agentDtmf;
			if(this.state.agentRows.some(checkDtmf))
			{
					Notify.notify(this.state.agentDtmf+ ' DTMF is already selected');
					return;
			}
			this.state.agentRows.push(item);
			this.setState({
				agentRows: this.state.agentRows
			});
			this.state.patchList = {
				"patchList": this.state.agentRows
			}
		}
		else {
			Notify.notify("Please select Agent and DTMF");
		}
	};
	handleRemoveRow = () => {
		this.setState({
			agentRows: this.state.agentRows.slice(0, -1)
		});
	};

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	
	setUploadType = (type, uploadTypeKey) => {
		this.setState({
			[uploadTypeKey]: type,
		});
	}

	onFileChangeHandler = event => {
		//if ( this.checkMimeType( event ) && this.checkFileSize( event ) ) {
		if (this.checkMimeType(event)) {

		}
	}

	// Add Campaign
	handleAddCampaignSubmit = async (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		console.log(formData);
		if (formData.agentRows.length === 0 && formData.templateId === 2) {
			Notify.notify('Please add Agent and DTMF Row');
			return;
		}
		const date = formData.sendDate;
		let sendDateTime = this.addZero(date.getFullYear());
		sendDateTime += "-";
		sendDateTime += this.addZero((date.getMonth() + 1));
		sendDateTime += "-"
		sendDateTime += this.addZero(date.getDate());
		sendDateTime += " "
		sendDateTime += this.addZero(date.getHours());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getMinutes());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getSeconds());
		var baseId = '';
		await this.uploadBaseAndPromptFiles(formData)
			.then(([resp]) => {
				if(formData.baseFileType === 'newBaseFile')
				{
					if(resp.baseId === undefined)
					{
						Notify.notify(resp.message);
						return;
					}
					else
					{
						baseId = resp.baseId;
					}
				}
				else if (formData.baseFileType === 'oldBaseFile') {
					baseId = resp;
				}
				else if(formData.baseFileType === 'contactList')
				{
					baseId = resp.baseId;
				}
				if (this.state.patchList === '' && formData.templateId === 2) {
					this.state.patchList = {
						"patchList": this.state.agentRows
					}
					//Notify.notify("Please add Agent Row");
					//return;
				}
				if(!this.state.welcomePrompt && this.state.templateId != 0)
				{
					this.state.oldWelcomeId='';
				}
				if(!this.state.noinputPrompt)
				{
					this.state.oldNoinputId='';
				}
				if(!this.state.wronginputPrompt)
				{
					this.state.oldWronginputId='';
				}
				if(!this.state.thanksPrompt)
				{
					this.state.oldThanksId='';
				}
				if(!this.state.sendSmsSuccess)
				{
					formData.smsApiSuccess='';
					formData.smsSuccessMessage='';
					formData.callDurationSMS=0;
				}
				if(!this.state.sendSmsFail)
				{
					formData.smsApiFail='';
					formData.smsFailMessage= '';
				}
				if(!this.state.sendSmsDtmf)
				{
					formData.smsApiDtmf='';
					formData.smsDtmfMessage='';
				}
				const data = {
					"userId": formData.userId,
					"campaignName": formData.campaignName,
					"templateId": formData.templateId,
					"dtmf": (formData.dtmf === '')?this.state.validDtmf:formData.dtmf,
					"baseId": baseId,
					"welcomePId": (formData.welcomeId === '')?this.state.oldWelcomeId:formData.welcomeId,
					"menuPId": (formData.menuId === '')?this.state.oldMenuId:formData.menuId,
					"noInputPId": (formData.noinputId === '')?this.state.oldNoinputId:formData.noinputId,
					"wrongInputPId": (formData.wronginputId == '')?this.state.oldWronginputId:formData.wronginputId,
					"thanksPId": (formData.thanksId === '')?this.state.oldThanksId:formData.thanksId,
					"scheduleTime": sendDateTime,
					"smsSuccessApi": formData.smsApiSuccess,
					"smsSuccessMessage": formData.smsSuccessMessage,
					"smsFailApi": formData.smsApiFail,
					"smsFailMessage": formData.smsFailMessage,
					"smsDtmfApi": formData.smsApiDtmf,
					"smsDtmfMessage": formData.smsDtmfMessage,
					"callDurationSMS": formData.callDurationSMS,
					"retries": 0,
					"retryInterval": 0,
					"agentRows": JSON.stringify(this.state.patchList),
					"channels": formData.channels,
					"menuWaitTime" : (formData.menuWaitTime === '')?this.state.oldMenuWaitTime:formData.menuWaitTime,
					"rePrompt": (formData.rePrompt === '')?this.state.oldRePrompt:formData.rePrompt,
					"ivrId": (formData.ivrId === '')?this.state.oldIvrId:formData.ivrId,
					"retryInterval": (formData.retryInterval === '')?this.state.oldRetryInterval:formData.retryInterval,
					"retries": (formData.retries === '')?this.state.oldRetries:formData.retries
				}
				this.addNewCampiagn(data);

			})

	}

	uploadBaseAndPromptFiles = async (formData) => {
		return new Promise(async (resolve, reject) => {

			var baseId = await this.maybeUploadBasefile(formData);
			resolve([baseId]);
			//reject();
		})
		//return Promise.all( [ this.maybeUploadBasefile( formData ), this.maybeUploadWelcomefile( formData ), this.maybeUploadMenufile( formData ), this.maybeUploadNoinputfile( formData ), this.maybeUploadWronginputfile( formData ), this.maybeUploadThanksfile( formData ) ] )
	}

	maybeUploadBasefile = async (formData) => {
		if (formData.baseFileType === 'oldBaseFile') {
			return formData.oldBaseId
		}
		else if (formData.baseFileType === 'contactList') {
			const fd = new FormData();
			fd.append('userId', formData.userId);
			fd.append('contactList', formData.contactList);
			//console.log(fd);
			return Fetcher.post(formData.apiPath + '/api/obd/contactlist', { method: 'POST', body: fd })
				.then(res => {
					return res;
				})
				.catch(err => { console.log('Error in uploading BaseUpload Files to Server', err) });
		}
		else {
			const fd = new FormData();
			fd.append('baseFile', formData.baseFileInput.current.files[0]);
			fd.append('baseName', formData.baseName);
			fd.append('userId', formData.userId);
			fd.append('contactList', '');
			return Fetcher.post(formData.apiPath + '/api/obd/baseupload', { method: 'POST', body: fd })
				.then(res => {
					return res;
				})
				.catch(err => { console.log('Error in uploading BaseUpload Files to Server', err) });
		}
	}

	addNewCampiagn = (data) => {
		console.log(data);
		return Fetcher.post(this.state.apiPath + '/api/obd/campaign/compose', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let campaignCreated = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					campaignCreated = true;
					Notify.notify(res.message);
				}
				this.setState({
					redirectToSummary: campaignCreated
				})
			})
			.catch(err => {
				console.log('Error in Add Campaign', err);
				Notify.notify('Error in Add Campaign');
			});
	}


	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	getFileNameFromUrl = (url) => {
		var n = url.lastIndexOf('/');
		return url.substring(n + 1);
	}

	checkMimeType = (event) => {
		let files = event.target.files
		let allowedTypes = ['text/plain', 'text/csv'];
		if (event.target.name === 'newContactsFile') {
			allowedTypes = ['text/plain', 'text/csv'];
		} else {
			allowedTypes = ['audio/wav'];
		}
		let err = [] // create empty array

		for (var x = 0; x < files.length; x++) {
			if (allowedTypes.every(type => files[x].type !== type)) {
				err[x] = files[x].type + ' is not a supported format\n';
				// assign message to array
			}
		};
		for (var z = 0; z < err.length; z++) { // loop create toast massage
			event.target.value = null;
		}
		return true;
	}

	checkFileSize = (event) => {
		let allowedSize = 1;
		if (event.target.name === 'newContactsFile') {
			allowedSize = 20;
		}

		let files = event.target.files
		let err = [];
		for (var x = 0; x < files.length; x++) {
			if (((files[x].size / 1024 / 1024).toFixed(2)) > allowedSize) {
				err[x] = files[x].size + ' is too large, please pick a smaller file\n';
			}
		};
		for (var z = 0; z < err.length; z++) {
			event.target.value = null;
		}
		return true;
	}

	render() {
		// Redirect to Summary if Campaign Added successfully.
		if (this.state.redirectToSummary === true) {
			return <Redirect to='/voice-campaign-summary' />
		}

		const agentRows = cloneDeep(this.state.agentRows);
		const agentsTable = agentRows.map((item, index) => {
			return <tr key={index}>
				<td>{this.state.agentRows[index].agentNumber}</td>
				<td>{this.state.agentRows[index].agentDtmf}</td>
				<td>
					<i onClick={() => this.handleRemoveRow()} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		});

		//console.log( 'Add Campaign State', this.state );
		const baseUploads = cloneDeep(this.state.baseUploads);
		const promptUploads = cloneDeep(this.state.promptUploads);
		const senderIdList = cloneDeep(this.state.senderIdList);
		const channelList = cloneDeep(this.state.channelsList);
		const ivrList = cloneDeep(this.state.ivrList);
		const sendLater = this.state.sendLater;
		let sendLaterDatepicker = '';
		let submitButtonlabel = 'Send Now';

		if (sendLater) {
			const filterTime = (date) => {
				const isPastTime = new Date().getTime() > date.getTime();
				return !isPastTime;
				};
			sendLaterDatepicker = <Row>
				<Col sm={10}>
					<Form.Group controlId="send-date">
						<Form.Label>Enter Date</Form.Label>
						<DatePicker
							className="form-control"
							showTimeSelect
							timeIntervals={15}
							minDate={new Date()}
							selected={this.state.sendDate}
							timeCaption="Time"
							dateFormat="yyyy-MM-dd hh:mm:ss"
							onChange={this.sendLaterDateChange}
							filterTime={filterTime}
						/>
					</Form.Group>
				</Col>
			</Row>

			submitButtonlabel = 'Compose Campaign';
		}

		let senderIdDropdown = '';
		if (!isEmpty(senderIdList) && (!('error' in senderIdList))) {
			senderIdDropdown = senderIdList.map((obj, index) => {
				return <option value={obj.cli} key={`senderid${index}`} >{obj.cli}</option>
			})

		} else {
			senderIdDropdown = <option value="" >No ID found</option>
		}

		let channelDropdown = '';
		if (!isEmpty(channelList) && (!('error' in channelList))) {
			channelDropdown = channelList.map((obj, index) => {
				return <option value={obj.slot} key={`channel${index}`} >{obj.slot}</option>
			})

		} else {
			channelDropdown = <option value="" >No Channel found</option>
		}

		if (!isEmpty(this.state.oldIvrList) && (!('error' in this.state.oldIvrList))) {
			this.state.oldIvrList.map((obj) => {
				this.state.oldIvrId = obj.ivrId;
				this.state.oldIvrName = obj.ivrName;
			})

		}

		let baseUploadDropdown = '';

		if (!isEmpty(baseUploads) && (!('error' in baseUploads))) {
			baseUploadDropdown = baseUploads.map((fileObj, index) => {
				return <option value={fileObj.baseId} key={`basefile${index}`} >{fileObj.baseName}</option>
			})

		} else {
			baseUploadDropdown = <option value="" >No Files</option>
		}

		let welcomeDropdown = '';
		let menuDropdown = '';
		let noinputDropdown = '';
		let wronginputDropdown = '';
		let thanksDropdown = '';

		if (!isEmpty(this.state.campaignPrompts) && (!('error' in this.state.campaignPrompts))) {
			this.state.campaignPrompts.map((fileObj) => {
				if (fileObj.promptCategory === 'welcome') {
					this.state.oldWelcomeId = fileObj.promptId;
					this.state.welcomePromptName = fileObj.promptName;
				}
				else if (fileObj.promptCategory === 'menu') {
					this.state.oldMenuId = fileObj.promptId;
					this.state.menuPromptName = fileObj.promptName;
				}
				else if (fileObj.promptCategory === 'noinput') {
					this.state.oldNoinputId = fileObj.promptId;
					this.state.noinputPromptName = fileObj.promptName;
				}
				else if (fileObj.promptCategory === 'wronginput') {
					this.state.oldWronginputId = fileObj.promptId;
					this.state.wronginputPromptName = fileObj.promptName;
				}
				else if (fileObj.promptCategory === 'thanks') {
					this.state.oldThanksId = fileObj.promptId;
					this.state.thanksPromptName = fileObj.promptName;
				}
			})
		}

		if (!isEmpty(this.state.campaignSms) && (!('error' in this.state.campaignSms))) {
			this.state.campaignSms.map((fileObj) => {
				if (fileObj.smsEvent === 'CALL_CONNECTED') {
					// this.state.smsApiSuccess = fileObj.smsText;
					// this.state.callDurationSMS = fileObj.callDurationSMS;
				}
				else if (fileObj.smsEvent === 'VALID_DTMF') {
					// this.state.smsApiDtmf = fileObj.smsText;
				}
				else if (fileObj.smsEvent === 'CALL_FAILED') {
					// this.state.smsApiFail = fileObj.smsText;
				}
			})
		}

		let ivrDropdown = '';

		if (!isEmpty(ivrList) && (!('error' in ivrList))) {
			ivrDropdown = Array.isArray(ivrList)?ivrList.map((fileObj, index) => {
				return <option value={fileObj.ivrId} key={`basefile${index}`} >{fileObj.ivrName}</option>
			}):[]

		} else {
			ivrDropdown = <option value="" >No Files</option>
		}

		if (!isEmpty(promptUploads) && (!('error' in promptUploads))) {

			welcomeDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'welcome') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					welcomeDropdown = <option value="" >No Files</option>
				}
			})

			menuDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'menu') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					menuDropdown = <option value="" >No Files</option>
				}
			})

			noinputDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'noinput') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					noinputDropdown = <option value="" >No Files</option>
				}
			})

			wronginputDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'wronginput') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					wronginputDropdown = <option value="" >No Files</option>
				}
			})

			thanksDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'thanks') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					thanksDropdown = <option value="" >No Files</option>
				}
			})
		}

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddCampaignSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Clone Voice Campaign</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Control required name="campaignName" onChange={e => this.setvalue(e)} type="text" placeholder="Campaign Name" />
										</Form.Group>
									</Col>
								</Row>
								{(this.state.templateId === 1) &&
									<Row>
										<Col sm={4}>
											<Form.Group controlId="sender-id">
												<Form.Label>Select DTMF</Form.Label>
												<Form.Select required name="dtmf" onChange={(e) => { this.handleSelect(e.target.selectedOptions) }} multiple as="select">
													<option selected value={this.state.validDtmf}>{this.state.validDtmf}</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													{/* <option value="*">*</option> */}
												</Form.Select>
											</Form.Group>
										</Col>
									</Row>}
									{(this.state.templateId === 3) && <Row>
									<Col sm={3}>
										<Form.Label>Select IVR</Form.Label>
										<Form.Group>
											<Form.Select as="select" required name="ivrId" onChange={e => this.setvalue(e)}>
												<option value={this.state.oldIvrId}>{this.state.oldIvrName}</option>
												{ivrDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									</Row>}
									{(this.state.templateId === 0) && <Row>
									<Col sm={12}>
										<Form.Label>Upload Contacts</Form.Label>
										<Tabs defaultActiveKey="oldBaseFile" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers(Only 50K numbers can be copied)">
												<Form.Group >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" maxLength="549999" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group>
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="this.state.oldBaseId">{this.state.oldBaseName}</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}
								{(this.state.templateId === 1) && <Row>
									<Col sm={12}>
										<Form.Label>Upload Contacts</Form.Label>
										<Tabs defaultActiveKey="oldBaseFile" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers(Only 50K numbers can be copied)">
												<Form.Group >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" maxLength="549999" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group>
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
													<option value="this.state.oldBaseId">{this.state.oldBaseName}</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}
								{(this.state.templateId === 2) && <Row>
									<Col sm={12}>
										<Form.Label>Upload Contacts</Form.Label>
										<Tabs defaultActiveKey="oldBaseFile" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers(Only 50K numbers can be copied)">
												<Form.Group >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" maxLength="549999" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group>
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
													<option value="this.state.oldBaseId">{this.state.oldBaseName}</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}
								{(this.state.templateId === 3) && <Row className="mb-2">
									<Col sm={12}>
										<Form.Label>Upload Contacts</Form.Label>
										<Tabs defaultActiveKey="oldBaseFile" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers(Only 50K numbers can be copied)">
												<Form.Group >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" maxLength="549999" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group>
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
													<option value="this.state.oldBaseId">{this.state.oldBaseName}</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}
								<Row>
									<Col sm={6}>
										<Form.Group ></Form.Group>
									</Col>
								</Row>
								{(this.state.templateId === 1) && <Row>
									<Col sm={3}>
										<Form.Label>Upload Menu Wav File</Form.Label>
										<Form.Group>
											<Form.Select as="select" required name="menuId" onChange={e => this.setvalue(e)}>
												<option value={this.state.oldMenuId}>{this.state.menuPromptName}</option>
												{menuDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
											<Form.Label>Menu Wait Time</Form.Label>
											<Form.Group controlId="sender-id">
												<Form.Select required name="menuWaitTime" onChange={e => this.setvalue(e)} value={this.state.menuWaitTime} as="select">
													<option value={this.state.oldMenuWaitTime}>{this.state.oldMenuWaitTime}</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
												</Form.Select>
											</Form.Group>
									</Col>
									<Col sm={3}>
											<Form.Label>Re-Prompt</Form.Label>
											<Form.Group>
												<Form.Select required name="rePrompt" onChange={e => this.setvalue(e)} value={this.state.rePrompt} as="select">
													<option value={this.state.oldRePrompt}>{this.state.oldRePrompt}</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
												</Form.Select>
											</Form.Group>
									</Col>
								</Row>}
								{(this.state.templateId === 2) && <Row>
									<Col sm={3}>
										<Form.Label>Upload Menu Wav File</Form.Label>
										<Form.Group>
											<Form.Select as="select" required name="menuId" onChange={e => this.setvalue(e)}>
											<option value={this.state.oldMenuId}>{this.state.menuPromptName}</option>
												{menuDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
											<Form.Label>Menu Wait Time</Form.Label>
											<Form.Group controlId="sender-id">
												<Form.Select required name="menuWaitTime" onChange={e => this.setvalue(e)} value={this.state.menuWaitTime} as="select">
												<option value={this.state.oldMenuWaitTime}>{this.state.oldMenuWaitTime}</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
												</Form.Select>
											</Form.Group>
									</Col>
									<Col sm={3}>
											<Form.Label>Re-Prompt</Form.Label>
											<Form.Group>
												<Form.Select required name="rePrompt" onChange={e => this.setvalue(e)} value={this.state.rePrompt} as="select">
												<option value={this.state.oldRePrompt}>{this.state.oldRePrompt}</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
												</Form.Select>
											</Form.Group>
									</Col>
								</Row>}

								<Row>
									<Col sm={6}>
										<Form.Group ></Form.Group>
									</Col>
								</Row>

								{this.state.templateId === 0 && <Row>
									<Col sm={4}>
										<Form.Label>Upload Wav File</Form.Label>
										<Form.Select as="select" required name="welcomeId" onChange={e => this.setvalue(e)}>
											<option value={this.state.oldWelcomeId}>{this.state.welcomePromptName}</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
								</Row>}

								{(this.state.templateId === 1) && <Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Check checked={this.state.welcomePrompt} onChange={(e) => this.toggleChange(e, 'welcomePrompt')} custom inline name="welcomePrompt" label="Welcome File" id="welcomePrompt" type="checkbox" />
											<Form.Check checked={this.state.noinputPrompt} onChange={(e) => this.toggleChange(e, 'noinputPrompt')} custom inline name="noinputPrompt" label="Noinput File" id="noinputPrompt" type="checkbox" />
											<Form.Check checked={this.state.wronginputPrompt} onChange={(e) => this.toggleChange(e, 'wronginputPrompt')} custom inline name="wronginputPrompt" label="Wronginput File" id="wronginputPrompt" type="checkbox" />
											<Form.Check checked={this.state.thanksPrompt} onChange={(e) => this.toggleChange(e, 'thanksPrompt')} custom inline name="thanksPrompt" label="Thanks File" id="thanksPrompt" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>}

								{(this.state.templateId === 2) && <Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Check checked={this.state.welcomePrompt} onChange={(e) => this.toggleChange(e, 'welcomePrompt')} custom inline name="welcomePrompt" label="Welcome File" id="welcomePrompt" type="checkbox" />
											<Form.Check checked={this.state.noinputPrompt} onChange={(e) => this.toggleChange(e, 'noinputPrompt')} custom inline name="noinputPrompt" label="Noinput File" id="noinputPrompt" type="checkbox" />
											<Form.Check checked={this.state.wronginputPrompt} onChange={(e) => this.toggleChange(e, 'wronginputPrompt')} custom inline name="wronginputPrompt" label="Wronginput File" id="wronginputPrompt" type="checkbox" />
											<Form.Check checked={this.state.thanksPrompt} onChange={(e) => this.toggleChange(e, 'thanksPrompt')} custom inline name="thanksPrompt" label="Thanks File" id="thanksPrompt" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>}

								{(this.state.templateId === 3) && <Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Check checked={this.state.noinputPrompt} onChange={(e) => this.toggleChange(e, 'noinputPrompt')} custom inline name="noinputPrompt" label="Noinput File" id="noinputPrompt" type="checkbox" />
											<Form.Check checked={this.state.wronginputPrompt} onChange={(e) => this.toggleChange(e, 'wronginputPrompt')} custom inline name="wronginputPrompt" label="Wronginput File" id="wronginputPrompt" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>}

								{(this.state.welcomePrompt) && <Row>
									<Col sm={4}>
										<Form.Label>Upload Welcome Wav File</Form.Label>
										<Form.Group>
											<Form.Select as="select" name="welcomeId" onChange={e => this.setvalue(e)}>
												<option value={this.state.oldWelcomeId}>{this.state.welcomePromptName}</option>
												{welcomeDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>}

								{(this.state.noinputPrompt) && <Row>
									<Col sm={4}>
										<Form.Label>Upload Noinput Wav File</Form.Label>

										<Form.Group>
											{/* <Form.Label>Select File</Form.Label> */}
											<Form.Select as="select" name="noinputId" onChange={e => this.setvalue(e)}>
											<option value={this.state.oldNoinputId}>{this.state.noinputPromptName}</option>
											{noinputDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>}

								{(this.state.wronginputPrompt) && <Row>
									<Col sm={4}>
										<Form.Label>Upload Wronginput Wav File</Form.Label>

										<Form.Group>
											{/* <Form.Label>Select File</Form.Label> */}
											<Form.Select as="select" name="wronginputId" onChange={e => this.setvalue(e)}>
											<option value={this.state.oldWronginputId}>{this.state.wronginputPromptName}</option>
												{wronginputDropdown}
											</Form.Select>
										</Form.Group>

									</Col>
								</Row>}

								{(this.state.thanksPrompt) && <Row>
									<Col sm={4}>
										<Form.Label>Upload Thanks Wav File</Form.Label>

										<Form.Group>
											{/* <Form.Label>Select File</Form.Label> */}
											<Form.Select as="select" name="thanksId" onChange={e => this.setvalue(e)}>
											<option value={this.state.oldThanksId}>{this.state.thanksPromptName}</option>
												{thanksDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>}
								{(this.state.templateId === 2) && <Row>
									<Col sm={6}>
										<Form.Group ><Form.Label>Add Agents</Form.Label></Form.Group>
									</Col>
								</Row>}

								{(this.state.templateId === 2) &&
									<Row>
										<Col sm={2}>

											<Form.Group controlId="campaign-name">
												<Form.Control name="agentNumber" onChange={e => this.setvalue(e)} value={this.state.agentNumber} type="text" placeholder="Agent Number" />
											</Form.Group>
										</Col>

										<Col sm={2}>
											<Form.Group controlId="sender-id">
												<Form.Select name="agentDtmf" onChange={e => this.setvalue(e)} value={this.state.agentDtmf} as="select">
													<option value="">Select DTMF</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													{/* <option value="*">*</option> */}
												</Form.Select>
											</Form.Group>
										</Col>
										<Col>
											<i onClick={this.handleAddRow} className="fa fa-plus-circle plus-icon"></i>
										</Col>
									</Row>}
								{(this.state.templateId === 2) && <Row>
									<Col sm={4}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Agent Number</th>
														<th>DTMF</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{agentsTable}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>}
								{(this.state.templateId === 0) && <Row>
									<Col sm={4}>
										<Form.Label>Select Channels</Form.Label>
										<Form.Select as="select" required name="channels" onChange={e => this.setvalue(e)}>
											<option value="this.state.channels">{this.state.channels}</option>
											{channelDropdown}
										</Form.Select>
									</Col>
								</Row>}
								{(this.state.templateId === 1) && <Row>
									<Col sm={4}>
										<Form.Label>Select Channels</Form.Label>
										<Form.Select as="select" required name="channels" onChange={e => this.setvalue(e)}>
											<option value="this.state.channels">{this.state.channels}</option>
											{channelDropdown}
										</Form.Select>
									</Col>
								</Row>}
								{(this.state.templateId === 2) && <Row>
									<Col sm={4}>
										<Form.Label>Select Channels</Form.Label>
										<Form.Select as="select" required name="channels" onChange={e => this.setvalue(e)}>
										<option value="this.state.channels">{this.state.channels}</option>
											{channelDropdown}
										</Form.Select>
									</Col>
								</Row>}
								{(this.state.templateId === 3) && <Row>
									<Col sm={4}>
										<Form.Label>Select Channels</Form.Label>
										<Form.Select as="select" required name="channels" onChange={e => this.setvalue(e)}>
										<option value="this.state.channels">{this.state.channels}</option>
											{channelDropdown}
										</Form.Select>
									</Col>
								</Row>}
								{<Row>
									<Col sm={3}>
											<Form.Label>Retry Attempts</Form.Label>
											<Form.Group>
												<Form.Select name="retries" onChange={e => this.setvalue(e)} as="select">
													<option value="this.state.oldRetries">{this.state.oldRetries}</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
												</Form.Select>
											</Form.Group>
									</Col>
									<Col sm={3}>
											<Form.Label>Retry Interval</Form.Label>
											<Form.Group>
												<Form.Select name="retryInterval" onChange={e => this.setvalue(e)} as="select">
													<option value="this.state.oldRetryInterval">{this.state.oldRetryInterval/60} Mins</option>
													<option value="900">15 Mins</option>
													<option value="1800">30 Mins</option>
												</Form.Select>
											</Form.Group>
									</Col>
								</Row>}
								{/*<Row>
									<Col sm={3}>
											<Form.Label>Retry Attempts</Form.Label>
											<Form.Group>
												<Form.Select required name="retries" onChange={e => this.setvalue(e)} value={this.state.rePrompt} as="select">
													<option value="">Select Retry Attempts</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
												</Form.Select>
											</Form.Group>
									</Col>
									<Col sm={3}>
											<Form.Label>Retry Interval(in Minutes)</Form.Label>
											<Form.Group>
												<Form.Select required name="retryInterval" onChange={e => this.setvalue(e)} value={this.state.rePrompt} as="select">
													<option value="">Select Retry Interval</option>
													<option value="0">15</option>
													<option value="1">30</option>
													<option value="2">45</option>
													<option value="3">60</option>
												</Form.Select>
											</Form.Group>
									</Col>
								</Row>*/}
								{this.state.templateId === 0 && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsSuccess' )} custom inline name="sendSmsSuccess" label="Send SMS on Success" id="sendSmsSuccess" type="checkbox" />
											</Form.Group>
										</Col>
										{(this.state.sendSmsSuccess) && 
											<Col sm={3}>
												<Form.Group controlId="sender-id">
													<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} value={this.state.callDurationSMS} as="select">
														<option value="this.state.callDurationSMS">{this.state.callDurationSMS}</option>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
														<option value="7">7</option>
														<option value="8">8</option>
														<option value="9">9</option>
														<option value="10">10</option>
														<option value="11">11</option>
														<option value="12">12</option>
														<option value="13">13</option>
														<option value="14">14</option>
														<option value="15">15</option>
														<option value="16">16</option>
														<option value="17">17</option>
														<option value="18">18</option>
														<option value="19">19</option>
														<option value="20">20</option>
														<option value="21">21</option>
														<option value="22">22</option>
														<option value="23">23</option>
														<option value="24">24</option>
														<option value="25">25</option>
														<option value="26">26</option>
														<option value="27">27</option>
														<option value="28">28</option>
														<option value="29">29</option>
														<option value="30">30</option>
														<option value="31">31</option>
														<option value="32">32</option>
														<option value="33">33</option>
														<option value="34">34</option>
														<option value="35">35</option>
														<option value="36">36</option>
														<option value="37">37</option>
														<option value="38">38</option>
														<option value="39">39</option>
														<option value="40">40</option>
														<option value="41">41</option>
														<option value="42">42</option>
														<option value="43">43</option>
														<option value="44">44</option>
														<option value="45">45</option>
														<option value="46">46</option>
														<option value="47">47</option>
														<option value="48">48</option>
														<option value="49">49</option>
														<option value="50">50</option>
														<option value="51">51</option>
														<option value="52">52</option>
														<option value="53">53</option>
														<option value="54">54</option>
														<option value="55">55</option>
														<option value="56">56</option>
														<option value="57">57</option>
														<option value="58">58</option>
														<option value="59">59</option>
														<option value="50">60</option>
													</Form.Select>
												</Form.Group>
										</Col>}
									</Row>
									{(this.state.sendSmsSuccess) && <Row>
										<Col sm={6}>
											<Form.Group >
												<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; and SMS Text with &#123;MESSAGE&#125; in the API</Form.Label>
												<Form.Control value={this.state.smsApiSuccess} onChange={e => this.setvalue( e )} name="smsApiSuccess" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										<Col sm={6}>
											<Form.Group >
												<Form.Label>Note: Please Paste the SMS text here</Form.Label>
												<Form.Control required value={this.state.smsSuccessMessage} onChange={e => this.setvalue( e )} name="smsSuccessMessage" as="textarea" rows="2" />
											</Form.Group>
										</Col>
									</Row>}
								</Row>}
								{this.state.templateId === 0  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsFail} onChange={( e ) => this.toggleChange( e, 'sendSmsFail' )} custom inline name="sendSmsFail" label="Send SMS on Fail" id="sendSmsFail" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
									{(this.state.sendSmsFail) && <Row>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; and SMS Text with &#123;MESSAGE&#125; in the API</Form.Label>
												<Form.Control value={this.state.smsApiFail} onChange={e => this.setvalue( e )} name="smsApiFail" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS text here</Form.Label>
												<Form.Control required value={this.state.smsFailMessage} onChange={e => this.setvalue( e )} name="smsFailMessage" as="textarea" rows="2" />
											</Form.Group>
										</Col>
									</Row>}
								</Row>}
								
								{this.state.templateId === 1 && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsSuccess' )} custom inline name="sendSmsSuccess" label="Send SMS on Success" id="sendSmsSuccess" type="checkbox" />
											</Form.Group>
										</Col>
										{(this.state.sendSmsSuccess) && 
											<Col sm={3}>
												<Form.Group controlId="sender-id">
													<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} value={this.state.callDurationSMS} as="select">
														<option value="">Select Duration</option>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
														<option value="7">7</option>
														<option value="8">8</option>
														<option value="9">9</option>
														<option value="10">10</option>
														<option value="11">11</option>
														<option value="12">12</option>
														<option value="13">13</option>
														<option value="14">14</option>
														<option value="15">15</option>
														<option value="16">16</option>
														<option value="17">17</option>
														<option value="18">18</option>
														<option value="19">19</option>
														<option value="20">20</option>
														<option value="21">21</option>
														<option value="22">22</option>
														<option value="23">23</option>
														<option value="24">24</option>
														<option value="25">25</option>
														<option value="26">26</option>
														<option value="27">27</option>
														<option value="28">28</option>
														<option value="29">29</option>
														<option value="30">30</option>
														<option value="31">31</option>
														<option value="32">32</option>
														<option value="33">33</option>
														<option value="34">34</option>
														<option value="35">35</option>
														<option value="36">36</option>
														<option value="37">37</option>
														<option value="38">38</option>
														<option value="39">39</option>
														<option value="40">40</option>
														<option value="41">41</option>
														<option value="42">42</option>
														<option value="43">43</option>
														<option value="44">44</option>
														<option value="45">45</option>
														<option value="46">46</option>
														<option value="47">47</option>
														<option value="48">48</option>
														<option value="49">49</option>
														<option value="50">50</option>
														<option value="51">51</option>
														<option value="52">52</option>
														<option value="53">53</option>
														<option value="54">54</option>
														<option value="55">55</option>
														<option value="56">56</option>
														<option value="57">57</option>
														<option value="58">58</option>
														<option value="59">59</option>
														<option value="50">60</option>
													</Form.Select>
												</Form.Group>
										</Col>}
									</Row>
									{(this.state.sendSmsSuccess) && <Row>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; and SMS Text with &#123;MESSAGE&#125; in the API</Form.Label>
												<Form.Control value={this.state.smsApiSuccess} onChange={e => this.setvalue( e )} name="smsApiSuccess" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										<Col sm={6}>
											<Form.Group >
												<Form.Label>Note: Please Paste the SMS text here</Form.Label>
												<Form.Control required value={this.state.smsSuccessMessage} onChange={e => this.setvalue( e )} name="smsSuccessMessage" as="textarea" rows="2" />
											</Form.Group>
										</Col>
									</Row>}
								</Row>}
								{this.state.templateId === 1  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsFail} onChange={( e ) => this.toggleChange( e, 'sendSmsFail' )} custom inline name="sendSmsFail" label="Send SMS on Fail" id="sendSmsFail" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
									{(this.state.sendSmsFail) && <Row>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; and SMS Text with &#123;MESSAGE&#125; in the API</Form.Label>
												<Form.Control value={this.state.smsApiFail} onChange={e => this.setvalue( e )} name="smsApiFail" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS text here</Form.Label>
												<Form.Control required value={this.state.smsFailMessage} onChange={e => this.setvalue( e )} name="smsFailMessage" as="textarea" rows="2" />
											</Form.Group>
										</Col>
									</Row>}
								</Row>}
								{this.state.templateId === 1  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsDtmf} onChange={( e ) => this.toggleChange( e, 'sendSmsDtmf' )} custom inline name="sendSmsDtmf" label="Send SMS on DTMF" id="sendSmsDtmf" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
									{(this.state.sendSmsDtmf) && <Row>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; and SMS Text with &#123;MESSAGE&#125; in the API</Form.Label>
												<Form.Control value={this.state.smsApiDtmf} onChange={e => this.setvalue( e )} name="smsApiDtmf" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS text here</Form.Label>
												<Form.Control required value={this.state.smsDtmfMessage} onChange={e => this.setvalue( e )} name="smsDtmfMessage" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										</Row>}
								</Row>}
								{this.state.templateId === 2 && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsSuccess' )} custom inline name="sendSmsSuccess" label="Send SMS on Success" id="sendSmsSuccess" type="checkbox" />
											</Form.Group>
										</Col>
										{(this.state.sendSmsSuccess) && 
											<Col sm={3}>
												<Form.Group controlId="sender-id">
													<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} value={this.state.callDurationSMS} as="select">
														<option value="">Select Duration</option>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
														<option value="7">7</option>
														<option value="8">8</option>
														<option value="9">9</option>
														<option value="10">10</option>
														<option value="11">11</option>
														<option value="12">12</option>
														<option value="13">13</option>
														<option value="14">14</option>
														<option value="15">15</option>
														<option value="16">16</option>
														<option value="17">17</option>
														<option value="18">18</option>
														<option value="19">19</option>
														<option value="20">20</option>
														<option value="21">21</option>
														<option value="22">22</option>
														<option value="23">23</option>
														<option value="24">24</option>
														<option value="25">25</option>
														<option value="26">26</option>
														<option value="27">27</option>
														<option value="28">28</option>
														<option value="29">29</option>
														<option value="30">30</option>
														<option value="31">31</option>
														<option value="32">32</option>
														<option value="33">33</option>
														<option value="34">34</option>
														<option value="35">35</option>
														<option value="36">36</option>
														<option value="37">37</option>
														<option value="38">38</option>
														<option value="39">39</option>
														<option value="40">40</option>
														<option value="41">41</option>
														<option value="42">42</option>
														<option value="43">43</option>
														<option value="44">44</option>
														<option value="45">45</option>
														<option value="46">46</option>
														<option value="47">47</option>
														<option value="48">48</option>
														<option value="49">49</option>
														<option value="50">50</option>
														<option value="51">51</option>
														<option value="52">52</option>
														<option value="53">53</option>
														<option value="54">54</option>
														<option value="55">55</option>
														<option value="56">56</option>
														<option value="57">57</option>
														<option value="58">58</option>
														<option value="59">59</option>
														<option value="50">60</option>
													</Form.Select>
												</Form.Group>
										</Col>}
									</Row>
									{(this.state.sendSmsSuccess) &&  <Row>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; and SMS Text with &#123;MESSAGE&#125; in the API</Form.Label>
												<Form.Control value={this.state.smsApiSuccess} onChange={e => this.setvalue( e )} name="smsApiSuccess" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										<Col sm={6}>
											<Form.Group >
												<Form.Label>Note: Please Paste the SMS text here</Form.Label>
												<Form.Control required value={this.state.smsSuccessMessage} onChange={e => this.setvalue( e )} name="smsSuccessMessage" as="textarea" rows="2" />
											</Form.Group>
										</Col>
									</Row>}
								</Row>}
								{this.state.templateId === 2  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsFail} onChange={( e ) => this.toggleChange( e, 'sendSmsFail' )} custom inline name="sendSmsFail" label="Send SMS on Fail" id="sendSmsFail" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
									{(this.state.sendSmsFail) && <Row>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; and SMS Text with &#123;MESSAGE&#125; in the API</Form.Label>
												<Form.Control value={this.state.smsApiFail} onChange={e => this.setvalue( e )} name="smsApiFail" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS text here</Form.Label>
												<Form.Control required value={this.state.smsFailMessage} onChange={e => this.setvalue( e )} name="smsFailMessage" as="textarea" rows="2" />
											</Form.Group>
										</Col>
									</Row>}
								</Row>}
								{this.state.templateId === 2  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsDtmf} onChange={( e ) => this.toggleChange( e, 'sendSmsDtmf' )} custom inline name="sendSmsDtmf" label="Send SMS on DTMF" id="sendSmsDtmf" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
									{(this.state.sendSmsDtmf) && <Row>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; and SMS Text with &#123;MESSAGE&#125; in the API</Form.Label>
												<Form.Control value={this.state.smsApiDtmf} onChange={e => this.setvalue( e )} name="smsApiDtmf" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS text here</Form.Label>
												<Form.Control required value={this.state.smsDtmfMessage} onChange={e => this.setvalue( e )} name="smsDtmfMessage" as="textarea" rows="2" />
											</Form.Group>
										</Col>
									</Row>}
								</Row>}
								{this.state.templateId === 3 && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsSuccess' )} custom inline name="sendSmsSuccess" label="Send SMS on Success" id="sendSmsSuccess" type="checkbox" />
											</Form.Group>
										</Col>
										{(this.state.sendSmsSuccess) && 
											<Col sm={3}>
												<Form.Group controlId="sender-id">
													<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} value={this.state.callDurationSMS} as="select">
														<option value="">Select Duration</option>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
														<option value="7">7</option>
														<option value="8">8</option>
														<option value="9">9</option>
														<option value="10">10</option>
														<option value="11">11</option>
														<option value="12">12</option>
														<option value="13">13</option>
														<option value="14">14</option>
														<option value="15">15</option>
														<option value="16">16</option>
														<option value="17">17</option>
														<option value="18">18</option>
														<option value="19">19</option>
														<option value="20">20</option>
														<option value="21">21</option>
														<option value="22">22</option>
														<option value="23">23</option>
														<option value="24">24</option>
														<option value="25">25</option>
														<option value="26">26</option>
														<option value="27">27</option>
														<option value="28">28</option>
														<option value="29">29</option>
														<option value="30">30</option>
														<option value="31">31</option>
														<option value="32">32</option>
														<option value="33">33</option>
														<option value="34">34</option>
														<option value="35">35</option>
														<option value="36">36</option>
														<option value="37">37</option>
														<option value="38">38</option>
														<option value="39">39</option>
														<option value="40">40</option>
														<option value="41">41</option>
														<option value="42">42</option>
														<option value="43">43</option>
														<option value="44">44</option>
														<option value="45">45</option>
														<option value="46">46</option>
														<option value="47">47</option>
														<option value="48">48</option>
														<option value="49">49</option>
														<option value="50">50</option>
														<option value="51">51</option>
														<option value="52">52</option>
														<option value="53">53</option>
														<option value="54">54</option>
														<option value="55">55</option>
														<option value="56">56</option>
														<option value="57">57</option>
														<option value="58">58</option>
														<option value="59">59</option>
														<option value="50">60</option>
													</Form.Select>
												</Form.Group>
										</Col>}
									</Row>
									{(this.state.sendSmsSuccess) && <Row>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; and SMS Text with &#123;MESSAGE&#125; in the API</Form.Label>
												<Form.Control value={this.state.smsApiSuccess} onChange={e => this.setvalue( e )} name="smsApiSuccess" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										<Col sm={6}>
											<Form.Group >
												<Form.Label>Note: Please Paste the SMS text here</Form.Label>
												<Form.Control required value={this.state.smsSuccessMessage} onChange={e => this.setvalue( e )} name="smsSuccessMessage" as="textarea" rows="2" />
											</Form.Group>
										</Col>
									</Row>}
								</Row>}
								{this.state.templateId === 3  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsFail} onChange={( e ) => this.toggleChange( e, 'sendSmsFail' )} custom inline name="sendSmsFail" label="Send SMS on Fail" id="sendSmsFail" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
									{(this.state.sendSmsFail) && <Row>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; and SMS Text with &#123;MESSAGE&#125; in the API</Form.Label>
												<Form.Control value={this.state.smsApiFail} onChange={e => this.setvalue( e )} name="smsApiFail" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										<Col sm={6}>
											<Form.Group >
											<Form.Label>Note: Please Paste the SMS text here</Form.Label>
												<Form.Control required value={this.state.smsFailMessage} onChange={e => this.setvalue( e )} name="smsFailMessage" as="textarea" rows="2" />
											</Form.Group>
										</Col>
									</Row>}
								</Row>}
								<Row>
									<Col sm={6}>
										<Form.Group controlId="Send-later">
											<Form.Check checked={this.state.sendlater} onChange={(e) => this.toggleChange(e, 'sendLater')} custom inline name="send-later" label="Schedule Call" id="send-later" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>
								{sendLaterDatepicker}
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default CloneVoiceCampaign;