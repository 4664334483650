import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
 import { Card, ListGroup,Form,Badge,Alert, Modal, Row, Col, Button } from 'react-bootstrap';
 import { MdPlayCircle, MdQueue, MdTextsms } from "react-icons/md";
 import ReactAudioPlayer from 'react-audio-player';
 import { Notify } from "../../../Helpers/notification.js";
 import { Fetcher } from "../../../Helpers/fetcher.js";
 import './index.css';


class AddIVR extends Component {

    constructor(props) {
        super(props);
		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);

        this.state = {  
		   userId: props.app.userId,
		   apiPath: props.app.apiPath,        
           NodesData: [{
            NodeId: 1,
            ParentNodeId: 0,
            Level: 1,
            AudioId: 0            
           }],
           NodeDataApiUrl: {
            'root1_1': { enabled: false, sms_url: '', dtmf: '1_1', sms_text: '' },
            'root1_2': { enabled: false, sms_url: '', dtmf: '1_2', sms_text: '' },
            'root1_3': { enabled: false, sms_url: '', dtmf: '1_3', sms_text: '' },
            'root1_4': { enabled: false, sms_url: '', dtmf: '1_4', sms_text: '' },
            'root1_5': { enabled: false, sms_url: '', dtmf: '1_5', sms_text: '' },  
            'root1_1_1': { enabled: false, sms_url: '', dtmf: '1_1_1', sms_text: '' },
            'root1_1_2': { enabled: false, sms_url: '', dtmf: '1_1_2', sms_text: '' },
            'root1_1_3': { enabled: false, sms_url: '', dtmf: '1_1_3', sms_text: '' },
            'root1_1_4': { enabled: false, sms_url: '', dtmf: '1_1_4', sms_text: '' },
            'root1_1_5': { enabled: false, sms_url: '', dtmf: '1_1_5', sms_text: '' },  
            'root1_2_1': { enabled: false, sms_url: '', dtmf: '1_2_1', sms_text: '' },
            'root1_2_2': { enabled: false, sms_url: '', dtmf: '1_2_2', sms_text: '' },
            'root1_2_3': { enabled: false, sms_url: '', dtmf: '1_2_3', sms_text: '' },
            'root1_2_4': { enabled: false, sms_url: '', dtmf: '1_2_4', sms_text: '' },  
            'root1_2_5': { enabled: false, sms_url: '', dtmf: '1_2_5', sms_text: '' },
            'root1_3_1': { enabled: false, sms_url: '', dtmf: '1_3_1', sms_text: '' },
            'root1_3_2': { enabled: false, sms_url: '', dtmf: '1_3_2', sms_text: '' },
            'root1_3_3': { enabled: false, sms_url: '', dtmf: '1_3_3', sms_text: '' },
            'root1_3_4': { enabled: false, sms_url: '', dtmf: '1_3_4', sms_text: '' },
            'root1_3_5': { enabled: false, sms_url: '', dtmf: '1_3_5', sms_text: '' },
            
            'root1_4_1': { enabled: false, sms_url: '', dtmf: '1_4_1', sms_text: '' },
            'root1_4_2': { enabled: false, sms_url: '', dtmf: '1_4_2', sms_text: '' },
            'root1_4_3': { enabled: false, sms_url: '', dtmf: '1_4_3', sms_text: '' },
            'root1_4_4': { enabled: false, sms_url: '', dtmf: '1_4_4', sms_text: '' },
            'root1_4_5': { enabled: false, sms_url: '', dtmf: '1_4_5', sms_text: '' },

            'root1_5_1': { enabled: false, sms_url: '', dtmf: '1_5_1', sms_text: '' },
            'root1_5_2': { enabled: false, sms_url: '', dtmf: '1_5_2', sms_text: '' },
            'root1_5_3': { enabled: false, sms_url: '', dtmf: '1_5_3', sms_text: '' },
            'root1_5_4': { enabled: false, sms_url: '', dtmf: '1_5_4', sms_text: '' },
            'root1_5_5': { enabled: false, sms_url: '', dtmf: '1_5_5', sms_text: '' }
           },
		   ivrName: '',
		   context:'',
           apiModalelemId: 'root1_1',
           NotificationMessage:{IsDisplay: false, NotificationType: "warning", MessageTitle: "", MessageText: ""},
           MaximumChildNodesLimit : 5,
           IsDisplay_AudioPlayer_ModalPopup: false,
           fields: {},
           AudioFile_To_Play: "",
           AudioFilesData: [],
		   promptUploads: [],
           API_Base_URL: "https://obd1api.expressivr.com",
           Audio_Base_URL: "/assets/voice/",
           toggle_thankyou: false,
           loading: false,
        };
     }

     //--During page load
     componentDidMount() {      
        //--Get Folders-Data from API
		
        this.GetAudioFilesData();
    }

    RenderRemoveNodeIcon = node => {
        return (<i title="close" className="fa fa-close float-right" onClick={this.removeNode.bind(this, node)} />);
    };

    //--Get Audio-Files data (From API)
    GetAudioFilesData = () => {
		const userId = this.state.userId;
		const apiPath = this.state.apiPath;
		// Get PromptUpload Files List
		Fetcher.get(apiPath + '/api/obd/prompts/' + userId)
			.then(res => {
				this.setState({
					AudioFilesData: res
				})
			})
			.catch(err => { console.log('Error in fetching PromptUpload Files', err) });
		
    }

     //----Add New Node----
     AddNode = (_nodeData, e) => {
        
        //--Get Nodes-Data from State
        var _arr_NodesData = this.state.NodesData;        
        //--Set Default-Node-Id as (1)
        var _New_Node_Id = 1;
        //--Get Notification-Object
        var _obj_NotificationMsg = this.state.NotificationMessage;
        //--Get Maximum Child-Nodes-Limit
        var _childNodesLimit = this.state.MaximumChildNodesLimit;

        //--check if node-data exist
        if(_nodeData){
            
            //--Get All Child-Nodes of this Node
            var _arr_Child_NodesData = _arr_NodesData.filter(n => n.ParentNodeId === _nodeData.NodeId);
            //--Get Child-Node Level (after adding 1 into its Parent-Node's Level)
            var _NodeLevel = _nodeData.Level + 1;

            //--Check if child-nodes reach-out the maximum range
            if(_arr_Child_NodesData.length >= _childNodesLimit){
                //--Set Notification-Message Data
               // _obj_NotificationMsg.IsDisplay = true;
                //_obj_NotificationMsg.NotificationType = "warning";
                //_obj_NotificationMsg.MessageTitle = "Message";
                //_obj_NotificationMsg.MessageText = "Sorry, you can only create maximum "+ _childNodesLimit.toString() +" child-nodes!";
				Notify.notify("Sorry, you can only create maximum "+ _childNodesLimit.toString() +" child-nodes!");
                //--Update Notification-Data in State
                //this.setState({ NotificationMessage: _obj_NotificationMsg });
            }
            else{
                //----------Create New-Child-Node-----------
                //--Check if data exist in Nodes-Array
                if(_arr_NodesData.length > 0){
                    //--Get the New-Node-Id (by getting maximun Node-Id and then add 1 in it) 
                    _New_Node_Id = Math.max.apply(Math, _arr_NodesData.filter(val => val.Level != 4).map(function (event) {
                        return event.NodeId;
                    })) + 1;
                }

                //----Check if Last-Level-Node (then remove all existing nodes of Last-Level (4th Level))---
                if(_NodeLevel === 4){
                    //--Get All Indexes of Last-Level Nodes (4th Level)
                    var arr_indexes_last_level_nodes = [], _nd;
                    for(_nd = 0; _nd < _arr_NodesData.length; _nd++){

                        //--check if Last-Level Node (4th Level)
                        if(_arr_NodesData[_nd].Level === 4){
                            //--add index into array
                            arr_indexes_last_level_nodes.push(_nd);
                        }
                    }
                    //------------------------------------------------

                    //--Remove Last-Level Nodes (4th Level) from Array--
                    for(_nd = 0; _nd < arr_indexes_last_level_nodes.length; _nd++){
                        //--remove node from array by index
                        _arr_NodesData.splice(arr_indexes_last_level_nodes[_nd], 1);
                    }
                    //--------------------------------------------------
                }
                //-----------------------------------------------------------------------------------------

          
                //--Create new Node-Object
                var obj_node = {
                    "NodeId":_New_Node_Id,
                    "ParentNodeId": _nodeData.NodeId,
                    "Level": _NodeLevel,
                    "AudioId": 0 
                }

                //--Add new Node-Object into Array
                _arr_NodesData.push(obj_node);

                //--Update Node-Array in State
                this.setState({ NodesData: _arr_NodesData });
            }
        }
     }

	 setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
     //--Close Notification-Message Box
     CloseNotification = () => {
        //--Get Notification-Object
        var _obj_NotificationMsg = this.state.NotificationMessage;

        //--Set Notification-Message Data
        _obj_NotificationMsg.IsDisplay = false;
        _obj_NotificationMsg.NotificationType = "warning";
        _obj_NotificationMsg.MessageTitle = "";
        _obj_NotificationMsg.MessageText = "";

        //--Update Notification-Data in State
        this.setState({ NotificationMessage: _obj_NotificationMsg });
     }

     PlaySelectedAudioHandler = audio_id => {

        var _objNotificationMessageData = null;
        //--Get Audio-Files Data from State
        var _arr_AudioData = this.state.AudioFilesData;
        var _Audio_File_URL = "";

        //--Get Selected-Audio File Detail
        var _arr_AudioFile_Detail = _arr_AudioData.filter(a => a.promptId == audio_id);

        //--Check if Audio-Exist
        if(_arr_AudioFile_Detail.length > 0){

            //--Set Audio-File URL
            _Audio_File_URL = this.state.apiPath+this.state.Audio_Base_URL + this.state.userId  + "/prompts/" + _arr_AudioFile_Detail[0].fileName;
            //--Update Audio-URL and Show-Audio-Player Popup in State
            this.setState({ AudioFile_To_Play : _Audio_File_URL, IsDisplay_AudioPlayer_ModalPopup: true });
        }
        else {
            //--Set Error Notification-Message
            _objNotificationMessageData = {IsDisplay: true, MessageType: "danger", MessageTitle: "Error",MessageText: "Sorry, unable to play audio of this node!" };

            //--Display-Message
            //this.DisplayMessage_Handler(_objNotificationMessageData);
        }

     }

     //--Play Audio-File
     PlayAudio_Handler = (_nodeData, e) => {

        //--check if node-data exist
        if(_nodeData){

            //const thankyouNode = this.state.NodesData.filter(val => val.Level == 4);
            
            //--Check if Audio-File is selected or not
            if(_nodeData.AudioId > 0){

                this.PlaySelectedAudioHandler(_nodeData.AudioId);

            }
            else {

                const thankyouNode = this.state.NodesData.filter(val => val.Level == 4);
                
                if(thankyouNode){
                
                    this.PlaySelectedAudioHandler(thankyouNode[0].AudioId);
                
                }else{

                    //--Set Error Notification-Message
                    //_objNotificationMessageData = {IsDisplay: true, MessageType: "danger", MessageTitle: "Error",MessageText: "Please select audio-file to play!" };
                    Notify.notify("Please select audio-file to play!");
        
                    //--Display-Message
                    //this.DisplayMessage_Handler(_objNotificationMessageData);

                }

            }
        }
        else {
            //--Set Error Notification-Message
           // _objNotificationMessageData = {IsDisplay: true, MessageType: "danger", MessageTitle: "Error",MessageText: "Sorry, unable to play audio of this node!" };
			Notify.notify("Sorry, unable to play audio of this node!");
            //--Display-Message
            //this.DisplayMessage_Handler(_objNotificationMessageData);
        }
     }

     //--Close Audio-Player Popup
     Close_AudioPlayer_Popup = () => {
         //--Update Audio-URL and Show-Audio-Player Popup in State
         this.setState({ AudioFile_To_Play : "", IsDisplay_AudioPlayer_ModalPopup: false });
     }

     //--Submit Generated Nodes-Data for Save
     SubmitIVR_Handler = () => {
        this.setState({ loading: true });
        //--Get Nodes-Data from State
        var _arr_NodesData = this.state.NodesData;
        //--Get DTMF Fields Data
        var _objFields = this.state.fields;
        var _objNotificationMessageData = null;
        var _IVR_Nodes_Data = null, _IVR_Nodes_Api_Data = null;
		var _DTMF_Timeout_Val = 0;
        var _DTMF_Retry_Val = 0;
        //--Get Audio-Files Data from State
        var _arr_AudioData = this.state.AudioFilesData;
        var _arr_AudioFile_Detail = [];

        var _API_URL = this.state.apiPath + '/api/obd/campaign/ivr';

        const thankyouHangupKey = this.state.toggle_thankyou ? 'end': 'hangup';

        try {
             
            //--Check if Nodes-Data not exist
            if(_arr_NodesData.length > 0){

                //--Validate Fields-Data
                if(this.ValidateFields_Handler()){
                    //alert('valid');
					_DTMF_Timeout_Val = parseInt(this.state.fields["DTMF_Timeout"]);
                    _DTMF_Retry_Val = parseInt(this.state.fields["DTMF_Retry"]);
                    //--Get Root Parent-Node
                    var _arr_NodesData_Level_1 = _arr_NodesData.filter(n => n.ParentNodeId === 0);
                    let parentPromptId;

                    //--Check if Root-Node exist
                    if(_arr_NodesData_Level_1.length > 0){

                        parentPromptId = _arr_NodesData_Level_1[0].AudioId;

                        //--Get All Child-Nodes of Root-Node [Level-2]
                        var _arr_NodesData_Level_2 = _arr_NodesData.filter(n => n.ParentNodeId === _arr_NodesData_Level_1[0].NodeId);

                        //-------GET Selected Audio-File Name (Level-1)-------
                        //--Set Empty
                        _arr_AudioFile_Detail = [];
                        //--Get Selected-Audio File Detail
                        _arr_AudioFile_Detail = _arr_AudioData.filter(a => a.promptId === _arr_NodesData_Level_1[0].AudioId);

                        //------------------------------------------
						
                        //---------Bind Root-Node Data [Level-1]----------------
                        _IVR_Nodes_Data = {"root" : {
                            "node": "root",
                            "parent": "root",
                            "action": "play",
                            "audio": this.state.apiPath+this.state.Audio_Base_URL + this.state.userId  + "/prompts/" + _arr_AudioFile_Detail[0].fileName,
                            "dtmf": "true",
                            "dtmf_timeout": _DTMF_Timeout_Val,
                            "dtmf_retry": _DTMF_Retry_Val,
                            "next": {}
                        }};

                        if(_arr_NodesData_Level_2.length){
                            for(let key = 1; key <= _arr_NodesData_Level_2.length; key++){
                                _IVR_Nodes_Data.root.next[key] = `root_${key}`;
                            }
                        }else{
                            for(let key = 1; key <= this.state.MaximumChildNodesLimit; key++){
                                _IVR_Nodes_Data.root.next[key] = thankyouHangupKey;
                            }
                        }
                        //-------------------------------------------------------

                        //--------------Bind Nodes-Data [Level-2]----------------
                        for(var j = 0; j < _arr_NodesData_Level_2.length; j++){

                            //--Get All Child-Nodes of specific Level-2-Node [Level-3]
                            var _arr_NodesData_Level_3 = _arr_NodesData.filter(n => n.ParentNodeId === _arr_NodesData_Level_2[j].NodeId);

                            //-------GET Selected Audio-File Name (Level-2)-------
                            //--Set Empty
                            _arr_AudioFile_Detail = [];
                            //--Get Selected-Audio File Detail
                            _arr_AudioFile_Detail = _arr_AudioData.filter(a => a.promptId === _arr_NodesData_Level_2[j].AudioId);

                            //------------------------------------------

                            _IVR_Nodes_Data["root_" + (j + 1)] =  {
                                    "node": "root_"+ (j + 1),
                                    "parent": "root",
                                    "action": "play",
                                    "audio": this.state.apiPath+this.state.Audio_Base_URL + this.state.userId  + "/prompts/" + _arr_AudioFile_Detail[0].fileName,
                                    "dtmf": "true",
									"dtmf_timeout": _DTMF_Timeout_Val,
                                    "dtmf_retry": _DTMF_Retry_Val,
                                    "next": {}
                            };

                            if(_arr_NodesData_Level_3.length){
                                for(let key = 1; key <= _arr_NodesData_Level_3.length; key++){
                                    _IVR_Nodes_Data[`root_${(j + 1)}`].next[key] = `root_${j+1}_${key}`;
                                }
                            }else{
                                for(let key = 1; key <= this.state.MaximumChildNodesLimit; key++){
                                    _IVR_Nodes_Data[`root_${(j + 1)}`].next[key] = thankyouHangupKey;
                                }
                            }

                        }
                        //-------------------------------------------------------

                        //--------------Bind Nodes-Data [Level-3]----------------
                        for(var j = 0; j < _arr_NodesData_Level_2.length; j++){

                             //--Get All Child-Nodes of specific Level-2-Node [Level-3]
                             var _arr_NodesData_Level_3 = _arr_NodesData.filter(n => n.ParentNodeId === _arr_NodesData_Level_2[j].NodeId);

                             for(var k = 0; k < _arr_NodesData_Level_3.length; k++){

                                 //-------GET Selected Audio-File Name (Level-3)-------
                                //--Set Empty
                                _arr_AudioFile_Detail = [];
                                //--Get Selected-Audio File Detail
                                _arr_AudioFile_Detail = _arr_AudioData.filter(a => a.promptId === _arr_NodesData_Level_3[k].AudioId);

                                //------------------------------------------

                                _IVR_Nodes_Data["root_" + (j + 1) + "_" + (k + 1)] = {
                                        "node": "root_" + (j + 1) + "_" + (k + 1),
                                        "parent": "root_" + (j + 1),
                                        "action": "play",
                                        "audio": this.state.apiPath+this.state.Audio_Base_URL + this.state.userId  + "/prompts/" + _arr_AudioFile_Detail[0].fileName,
                                        "dtmf": "true",
										"dtmf_timeout": _DTMF_Timeout_Val,
                                        "dtmf_retry": _DTMF_Retry_Val,
                                        "next": {
                                                "1": thankyouHangupKey,
                                                "2": thankyouHangupKey,
                                                "3": thankyouHangupKey,
                                                "4": thankyouHangupKey,
                                                "5": thankyouHangupKey
                                        }
                                };
                             }
                        }
                        //-------------------------------------------------------

                        //--------------Bind Last-Node-Data [Level-4]----------------
                         //--Get Last-Node [Level-4]
                        var _arr_NodesData_Level_4 = _arr_NodesData.filter(n => n.Level == 4);
                        //--check if last-node exist
                        if(_arr_NodesData_Level_4.length > 0){

                            //-------GET Selected Audio-File Name (Level-3)-------
                            //--Set Empty
                            _arr_AudioFile_Detail = [];
                            //--Get Selected-Audio File Detail
                            _arr_AudioFile_Detail = _arr_AudioData.filter(a => a.promptId ==  _arr_NodesData_Level_4[0].AudioId);

                            //------------------------------------------

                            _IVR_Nodes_Data["end"] = {
                                    "node": "end",
                                    "parent": "end",
                                    "action": "play",
                                    "audio": this.state.apiPath+this.state.Audio_Base_URL + this.state.userId  + "/prompts/" + _arr_AudioFile_Detail[0].fileName,
                                    "dtmf": "false"
                            };
                        }
                        //-------------------------------------------------------
        

                        const _NodeDataApiUrl = this.state.NodeDataApiUrl;
                        const NodeDataApiUrlArr = [];
                        for(const key in _NodeDataApiUrl){
                            
                            if(_NodeDataApiUrl.hasOwnProperty(key)){
                                if(_NodeDataApiUrl[key]['sms_url'] != ''){
                                    NodeDataApiUrlArr.push({ 'sms_url': _NodeDataApiUrl[key]['sms_url'], 'dtmf': _NodeDataApiUrl[key]['dtmf'], 'sms_text': _NodeDataApiUrl[key]['sms_text'] });
                                }
                            }

                        }
						var smsApi = {
							"smsApi": NodeDataApiUrlArr
						}
                        var _ivrData = {"ivr" : _IVR_Nodes_Data};
                        var _campaignData = {
                            "userId": this.state.userId,
                            "ivrName": this.state.ivrName,
							"context": this.state.ivrName+'_'+this.state.userId,
                            "ivrFlow": JSON.stringify(_ivrData),
                            "smsApi": JSON.stringify(smsApi),
                            "parentPromptId": parentPromptId,
                        }
                        
                        //--Call API to Submit IVR-Flow Data
                        return Fetcher.post(this.state.apiPath + '/api/obd/campaign/ivr', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(_campaignData) })
                            .then(res => {
                                let ivrCreated = false;
                                if (res.message === 'Parameters Incorrect') {
                                    Notify.notify(res.message);
                                } else {
                                    ivrCreated = true;
                                    Notify.notify(res.message);
                                }
                                this.setState({ loading: false });
                                this.setState({
                                    redirectToSummary: ivrCreated
                                })
                            })
                            .catch(err => {
                                console.log('Error in Adding IVR', err);
                                this.setState({ loading: false });
                                Notify.notify('Error in Adding IVR');
                            });
                    }
                    else {                
                        //--Set Error Notification-Message
                        //_objNotificationMessageData = {IsDisplay: true, MessageType: "danger", MessageTitle: "Error",MessageText: "Sorry, nodes data is not valid!" };
						this.setState({ loading: false });
                        Notify.notify("Sorry, nodes data is not valid!");
        
                        //--Display-Message
                        //this.DisplayMessage_Handler(_objNotificationMessageData);
                    } 
                }                             
            }
            else {                
                //--Set Error Notification-Message
                //_objNotificationMessageData = {IsDisplay: true, MessageType: "danger", MessageTitle: "Error",MessageText: "Sorry, nodes data is not valid!" };
				this.setState({ loading: false });
                Notify.notify("Sorry, nodes data is not valid!");
                //--Display-Message
                //this.DisplayMessage_Handler(_objNotificationMessageData);
            }
          }
          catch(err) {
            console.log(err);
            //--Set Error Notification-Message
            //_objNotificationMessageData = {IsDisplay: true, MessageType: "danger", MessageTitle: "Error",MessageText: "Sorry, unable to submit nodes-data, please try again later!" };
            this.setState({ loading: false });
            Notify.notify("Sorry, unable to submit nodes-data, please try again later!");
            //--Display-Message
            //this.DisplayMessage_Handler(_objNotificationMessageData);
          }
     }

     //--Validate Fields-Data
     ValidateFields_Handler = () => {
        
         let fields = this.state.fields;
         let formIsValid = true;
		 var letterNumber = /^[0-9a-zA-Z]+$/;
         var _objNotificationMessageData = null;
         //--Get Nodes-Data from State
        var _arr_NodesData = this.state.NodesData;

         //--Get Nodes (of which Audio-File not selected)
         var _arr_Nodes_AudioNotSelected = _arr_NodesData.filter(n => n.AudioId <= 0);
         const _arr_Nodes_ThankyouAudioNotSelected = Boolean(this.state.toggle_thankyou && _arr_NodesData.filter(v => v.Level == 4 && v.AudioId).length == 0);


         //DTMF_Timeout Validation
         if (!fields["DTMF_Timeout"] || fields["DTMF_Timeout"] <= 0) {
			formIsValid = false;
		   
			//--Set Error Notification-Message
		   //_objNotificationMessageData = {IsDisplay: true, MessageType: "danger", MessageTitle: "Error",MessageText: "Please select DTMF-Timeout!" };
		   Notify.notify("Please select Wait Time");
		   //--Display-Message
		   //this.DisplayMessage_Handler(_objNotificationMessageData);
		}
		//DTMF_Retry Validation
		else if (!fields["DTMF_Retry"] || fields["DTMF_Retry"] <= 0) {
		   formIsValid = false;
		  
		   //--Set Error Notification-Message
		  //_objNotificationMessageData = {IsDisplay: true, MessageType: "danger", MessageTitle: "Error",MessageText: "Please select DTMF-Retry!" };
			Notify.notify("Please select RePrompt");
		  //--Display-Message
		  //this.DisplayMessage_Handler(_objNotificationMessageData);
	   }
        //--Check if Audio-File not selected of any Node
        else if(_arr_Nodes_AudioNotSelected.length > 0 || _arr_Nodes_ThankyouAudioNotSelected){
            formIsValid = false;
            //--Set Error Notification-Message
          // _objNotificationMessageData = {IsDisplay: true, MessageType: "danger", MessageTitle: "Error",MessageText: "Please select the audio-file of each node!" };
		  Notify.notify("Please select audio file");

           //--Display-Message
           //this.DisplayMessage_Handler(_objNotificationMessageData);
        }
		else if(this.state.ivrName === '')
		{
			formIsValid = false;
			Notify.notify("Please enter Ivr Name");
		}
		else if(!this.state.ivrName.match(letterNumber))
		{
			formIsValid = false;
			Notify.notify("Special Characters not allowed");
		}
        if (!formIsValid) {
            this.setState({ loading: false }); 
        } 
        return formIsValid;
     }

     //--Display Notification-Message
     DisplayMessage_Handler = (_objMessage) => {
        //--Get Notification-Object
        var _obj_NotificationMsg = this.state.NotificationMessage;

        //--check if message-exist
        if(_objMessage){
            //--Set Error Notification-Message
            _obj_NotificationMsg.IsDisplay = _objMessage.IsDisplay;
            _obj_NotificationMsg.NotificationType =  _objMessage.MessageType;
            _obj_NotificationMsg.MessageTitle = _objMessage.MessageTitle;
            _obj_NotificationMsg.MessageText = _objMessage.MessageText;

            //--Update Notification-Data in State
            this.setState({ NotificationMessage: _obj_NotificationMsg });
        }
     }

    //---Change Fields-Value [DTMF-Timeout and DTMF-Retry]
    FieldValueChange_Handler(field, e) {
        
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });

        console.log(fields);
    }

    selectThankyouAudioHandler = (e) => {

        const _arr_NodesData = this.state.NodesData;
        if(_arr_NodesData.filter(val => val.Level == 4).length){
            
            for(let val of _arr_NodesData){
                if(val.Level == 4) val.AudioId = e.target.value;
            }

        }else{
            _arr_NodesData.push({AudioId: e.target.value, Level: 4 });
        }
        this.setState({ NodesData: _arr_NodesData });

    }

    SelectAudio_Handler = (_nodeData, e) => {
        
        var _selected_AudioId = e.target.value;
        //--Get Nodes-Data from State
        var _arr_NodesData = this.state.NodesData; 
        var _index_val = -1;
        
        //--check if valid audio-id
        if(_selected_AudioId >= 0){
             //--check if node-data exist
            if(_nodeData){

                //--Get the Index of Node
                var filteredObj = _arr_NodesData.find(function(item, i){
                    if(item.NodeId === _nodeData.NodeId){
                      _index_val = i;
                      return i;
                    }
                  });

                  //--Set Audio-Id as Selected-Audio (of Node)
                  _arr_NodesData[_index_val].AudioId = parseInt(_selected_AudioId);

                  //--Update Node-Array in State
                  this.setState({ NodesData: _arr_NodesData });
            }
        }
    }

    //--Bind Audio Dropdown-List data
    Get_AudioListData_Dropdown = () => {        
        var _audioData = this.state.AudioFilesData;
        return Array.isArray(_audioData)?_audioData.map((audioData, i_audioData) => (
            <option value={audioData.promptId} name={audioData.fileName}>{audioData.fileName}</option> 
        )):[]
    }

    toggleAddApiModal = (_nodeData, elemId) => {
        this.setState( {
            showModal: true,
            apiModalelemId: elemId
        } );
    }

    hideModal = () => {
		this.setState( {
			showModal: false
		} );
	}

    onApiUrlUpdate = e => {
        const __nodeApiDataUrl = this.state.NodeDataApiUrl;
        __nodeApiDataUrl[e.target.name]['sms_url'] = e.target.value;
        this.setState({ NodeDataApiUrl: __nodeApiDataUrl});
    }

    onSmsTextUpdate = e => {
        const __nodeApiDataUrl = this.state.NodeDataApiUrl;
        __nodeApiDataUrl[e.target.name]['sms_text'] = e.target.value;
        this.setState({ NodeDataApiUrl: __nodeApiDataUrl});
    }

    onApiUrlCheckEnable = e => {
        const __nodeApiDataUrl = this.state.NodeDataApiUrl;
        __nodeApiDataUrl[e.target.name]['enabled'] = e.target.checked;
        this.setState({ NodeDataApiUrl: __nodeApiDataUrl});
    }

    toggleThankyouNode = e => {
        this.setState({toggle_thankyou: e.target.checked});
    }

    removeNode = node => {
        const isLevel2 = node.Level == 2; const givenNodeId = node.NodeId;
        const _nodesData = this.state.NodesData.filter(function(val){
            return (val.NodeId != givenNodeId) && (isLevel2 ? givenNodeId != val.ParentNodeId: true);
        });
        //const _nodesData = this.state.NodesData.filter(val => val.NodeId != node.NodeId);
        this.setState({NodesData: _nodesData})
    }

    render() {
		// Redirect to Summary if Campaign Added successfully.
		if (this.state.redirectToSummary === true) {
			return <Redirect to='/ivr-list' />
		}
        return (
           <React.Fragment>
            	<div>
				<Card>
				<Card.Header>Create IVR</Card.Header>
				<Card.Body>
                <div className="container mt-3">
					<Row>
						<Col sm={2}>
							<Form.Group>
								<Form.Control required name="ivrName" onChange={e => this.setvalue(e)} value={this.state.ivrName} type="text" placeholder="IVR Name" />
							</Form.Group>
						</Col>
						<Col sm={2}>
                            <Form.Select aria-label="Select" onChange={this.FieldValueChange_Handler.bind(this, "DTMF_Timeout")} value={this.state.fields["DTMF_Timeout"]}>
                                <option value="0">Menu Wait Time</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </Form.Select>
						</Col>
						<Col sm={2}>
                            <Form.Select aria-label="Default select example" onChange={this.FieldValueChange_Handler.bind(this, "DTMF_Retry")} value={this.state.fields["DTMF_Retry"]}>
                                <option value="0">RePrompt</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </Form.Select>
						</Col>
                        <Col sm={2}>
                            <Button variant="primary" disabled={this.state.loading} onClick={this.SubmitIVR_Handler.bind()}>Submit</Button>
                        </Col>
                    </Row>
                </div>
				<Form.Label className="lblStyle">IVR Flow</Form.Label>
                <div>
                    <Form.Check type="checkbox" custom inline className="float-left mr-0" name="toggle_thankyou" checked={this.state.toggle_thankyou} onChange={this.toggleThankyouNode} />
                    <Form.Label>Enable thankyou message</Form.Label>
                </div>
                <section className="treeMainContainer scroll">
                    <div className="treeContainer d_f">
                        {
                            //-------------------------------Bind Level-1 -----------------------------------
                            this.state.NodesData.filter(n => n.ParentNodeId === 0).map((nodeLevel_1, i_Level_1) => (
                            <div className="_NewBranch d_f">
                                <div className="_treeRoot d_f">
                                    <div className={"_treeBranch" + (this.state.NodesData.filter(n => n.ParentNodeId === nodeLevel_1.NodeId).length > 0 ? ' hasChildren' : '')}> 
                                        <div className="_treeRaw d_f">
                                            <div className="_treeLeaf d_f">
                                                <div className="t_Data d_f">
                                                    <p className="shortName">
                                                        <div>
                                                            <Card className="CardNodeStyle">
                                                                <ListGroup variant="flush">
                                                                    <ListGroup.Item>
                                                                    <MdPlayCircle className="Node_Play_Icon" onClick={this.PlayAudio_Handler.bind(this, nodeLevel_1)}></MdPlayCircle>
                                                                    <Form.Select aria-label="Default select example" onChange={this.SelectAudio_Handler.bind(this, nodeLevel_1)} value={nodeLevel_1.AudioId}>
                                                                        <option value={0} data-name="Select Audio">Select Audio</option>   
                                                                        {/* {
                                                                            //--Bind Audio-Files Data in Dropdown of Node
                                                                            this.state.AudioFilesData.map((audioData, i_audioData) => (
                                                                            <option value={audioData.promptId} name={audioData.fileName}>{audioData.fileName}</option>  
                                                                        ))}*/}
                                                                        {this.Get_AudioListData_Dropdown()}
                                                                    </Form.Select>
                                                                    </ListGroup.Item>
                                                                    <ListGroup.Item>
                                                                        <MdTextsms title="Add SMS api url" className="float-left send-sms-dtmf" onClick={this.toggleAddApiModal.bind(this, nodeLevel_1, `root${nodeLevel_1.Level}`)} />
                                                                        <MdQueue className="Node_Add_Icon" onClick={this.AddNode.bind(this, nodeLevel_1)}></MdQueue>
                                                                        <div className="lblChildernCountStyle"><Badge bg="secondary">{this.state.NodesData.filter(n => n.ParentNodeId === nodeLevel_1.NodeId).length}</Badge></div>
                                                                    </ListGroup.Item>                                
                                                                </ListGroup>
                                                            </Card>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            //-------------------------------Bind Level-2-----------------------------------
                                            //--Check if has-Child-Node (of Level-1)
                                            this.state.NodesData.filter(n => n.ParentNodeId === nodeLevel_1.NodeId).length > 0 
                                            ?
                                            <div className="_NewBranch d_f">
                                                {
                                                    this.state.NodesData.filter(n => n.ParentNodeId === nodeLevel_1.NodeId).map((nodeLevel_2, i_Level_2) => (
                                                    <div className="_treeRoot d_f">
                                                        <div className={"_treeBranch" + (this.state.NodesData.filter(n => n.ParentNodeId === nodeLevel_2.NodeId).length > 0 ? ' hasChildren' : '')}>
                                                            <div className="_treeRaw d_f">
                                                                <div className="_treeLeaf d_f">
                                                                    <div className="t_Data d_f">
                                                                        <p className="shortName">
                                                                            <div>
                                                                                <Card className="CardNodeStyle">
                                                                                    <ListGroup variant="flush">
                                                                                        <ListGroup.Item>
                                                                                        <MdPlayCircle className="Node_Play_Icon" onClick={this.PlayAudio_Handler.bind(this, nodeLevel_2)}></MdPlayCircle>{this.RenderRemoveNodeIcon(nodeLevel_2)}
                                                                                        <Form.Select aria-label="Default select example" onChange={this.SelectAudio_Handler.bind(this, nodeLevel_2)} value={nodeLevel_2.AudioId}>
                                                                                            <option value={0} data-name="Select Audio">Select Audio</option>   
                                                                                           
                                                                                            {this.Get_AudioListData_Dropdown()}
                                                                                        </Form.Select>
                                                                                        </ListGroup.Item>
                                                                                        <ListGroup.Item>
                                                                                            <MdTextsms title="Add SMS api url" className="float-left send-sms-dtmf" onClick={this.toggleAddApiModal.bind(this, nodeLevel_2, `root${nodeLevel_2.ParentNodeId}_${i_Level_2+1}`)} />
                                                                                            <MdQueue className="Node_Add_Icon" onClick={this.AddNode.bind(this, nodeLevel_2)}></MdQueue>
                                                                                            <div className="lblChildernCountStyle"><Badge bg="secondary">{this.state.NodesData.filter(n => n.ParentNodeId === nodeLevel_2.NodeId).length}</Badge></div>
                                                                                        </ListGroup.Item>                                
                                                                                    </ListGroup>
                                                                                </Card>
                                                                            </div>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                //-------------------------------Bind Level-3-----------------------------------
                                                                //--Check if has-Child-Node (of Level-2)
                                                                this.state.NodesData.filter(n => n.ParentNodeId === nodeLevel_2.NodeId).length > 0 
                                                                ?
                                                                <div className="_NewBranch d_f">
                                                                    {
                                                                        this.state.NodesData.filter(n => n.ParentNodeId === nodeLevel_2.NodeId).map((nodeLevel_3, i_Level_3) => (
                                                                        <div className="_treeRoot d_f">
                                                                            <div className={"_treeBranch" + (this.state.NodesData.filter(n => n.ParentNodeId === nodeLevel_3.NodeId).length > 0 ? ' hasChildren' : '')}>
                                                                                <div className="_treeRaw d_f">
                                                                                    <div className="_treeLeaf d_f">
                                                                                        <div className="t_Data d_f">
                                                                                            <p className="shortName">
                                                                                                <div>
                                                                                                    <Card className="CardNodeStyle">
                                                                                                        <ListGroup variant="flush">
                                                                                                            <ListGroup.Item>
                                                                                                                <MdPlayCircle className="Node_Play_Icon" onClick={this.PlayAudio_Handler.bind(this, nodeLevel_3)}></MdPlayCircle>{this.RenderRemoveNodeIcon(nodeLevel_3)}
                                                                                                                <Form.Select aria-label="Default select example" onChange={this.SelectAudio_Handler.bind(this, nodeLevel_3)} value={nodeLevel_3.AudioId}>
                                                                                                                    <option value={0} data-name="Select Audio">Select Audio</option>   
                                                                                                                
                                                                                                                    {this.Get_AudioListData_Dropdown()}
                                                                                                                </Form.Select>
                                                                                                            </ListGroup.Item>
                                                                                                            <ListGroup.Item>
                                                                                                                {/* <MdQueue className="Node_Add_Icon" onClick={this.AddNode.bind(this, nodeLevel_3)}></MdQueue>
                                                                                                                <div className="lblChildernCountStyle"><Badge bg="secondary">{this.state.NodesData.filter(n => n.ParentNodeId === nodeLevel_3.NodeId).length}</Badge></div> */}
                                                                                                            </ListGroup.Item>                                
                                                                                                        </ListGroup>
                                                                                                    </Card>
                                                                                                </div>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                {
                                                                                    //-------------------------------Bind Level-4-----------------------------------
                                                                                    //--Check if has-Child-Node (of Level-3)
                                                                                    // this.state.NodesData.filter(n => n.ParentNodeId === nodeLevel_3.NodeId).length > 0 
                                                                                    // ?
                                                                                    // <div className="_NewBranch d_f">
                                                                                    //     {
                                                                                    //         this.state.NodesData.filter(n => n.ParentNodeId === nodeLevel_3.NodeId).map((nodeLevel_4, i_Level_4) => (
                                                                                    //         <div className="_treeRoot d_f">
                                                                                    //             <div className={"_treeBranch" + (this.state.NodesData.filter(n => n.ParentNodeId === nodeLevel_4.NodeId).length > 0 ? ' hasChildren' : '')}>
                                                                                    //                 <div className="_treeRaw d_f">
                                                                                    //                     <div className="_treeLeaf d_f">
                                                                                    //                         <div className="t_Data d_f t_Data_lastLevelNodeStyle">
                                                                                    //                             <p className="shortName">
                                                                                    //                                 <div>
                                                                                    //                                     <Card className="CardNodeStyle">
                                                                                    //                                         <ListGroup variant="flush">
                                                                                    //                                             <ListGroup.Item>
                                                                                    //                                             <MdPlayCircle className="Node_Play_Icon" onClick={this.PlayAudio_Handler.bind(this, nodeLevel_4)}></MdPlayCircle>
                                                                                    //                                             <Form.Select aria-label="Default select example" onChange={this.SelectAudio_Handler.bind(this, nodeLevel_4)} value={nodeLevel_4.AudioId}>
                                                                                    //                                                 <option value={0} data-name="Select Audio">Select Audio</option>   
                                                                                                                                
                                                                                    //                                                 {this.Get_AudioListData_Dropdown()}
                                                                                    //                                             </Form.Select>
                                                                                    //                                             </ListGroup.Item>                                                                                                                                                              
                                                                                    //                                         </ListGroup>
                                                                                    //                                     </Card>
                                                                                    //                                 </div>
                                                                                    //                             </p>
                                                                                    //                         </div>
                                                                                    //                     </div>
                                                                                    //                 </div>
                                                                                    //             </div>
                                                                                    //         </div>

                                                                                    //     ))}
                                                                                    // </div>
                                                                                    // :
                                                                                    // ""
                                                                                }

                                                                            </div>
                                                                        </div>

                                                                    ))}
                                                                </div>
                                                                :
                                                                ""
                                                            }

                                                        </div>
                                                    </div>

                                                ))}
                                            </div>
                                            :
                                            ""
                                        }
                                            
    

                                    </div>
                                </div>
                            </div>
                        ))
                        }
                    </div>

                    {

this.state.toggle_thankyou ? 

<div className='treeContainer d_f'>
<div className="_treeRoot d_f">
    <div className="_treeRaw d_f">
        <div className="_treeLeaf d_f">
            <div className="t_Data d_f t_Data_lastLevelNodeStyle">
                <p className="shortName">
                    <div>
                        <Card className="CardNodeStyle">
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                <MdPlayCircle className="Node_Play_Icon" onClick={this.PlayAudio_Handler.bind(this)}></MdPlayCircle>
                                <Form.Select aria-label="Default select example" onChange={this.selectThankyouAudioHandler.bind(this)} value={this.state.NodesData.filter(v => v.Level == 4).length ? this.state.NodesData.filter(v => v.Level == 4)[0]['AudioId'] : ''}>
                                    <option value={0} data-name="Select Audio">Select Audio</option>   
                                
                                    {this.Get_AudioListData_Dropdown()}
                                </Form.Select>
                                </ListGroup.Item>                                                                                                                                                              
                            </ListGroup>
                        </Card>
                    </div>
                </p>
            </div>
        </div>
    </div>

</div>
                                </div>
:

<></>

}


                </section>
                
                <div className="AlertStyle">
                    <Alert show={this.state.NotificationMessage.IsDisplay} variant={this.state.NotificationMessage.NotificationType} onClose={this.CloseNotification.bind()} dismissible>
                        <Alert.Heading>{this.state.NotificationMessage.MessageTitle}</Alert.Heading>
                        <p>
                        {/* Sorry, you can only create maximum 3 child-nodes! */}
                        {this.state.NotificationMessage.MessageText}
                        </p>
                    </Alert>
                </div>

                <div>
                    <Modal
                        show={this.state.IsDisplay_AudioPlayer_ModalPopup}
                        onHide={this.Close_AudioPlayer_Popup}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                        <Modal.Title>Play Audio</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="AudioPlayerBoxStyle">
                                <ReactAudioPlayer
                                    //src="/file_example_WAV_2MG.wav"
                                    src={this.state.AudioFile_To_Play}
                                    autoPlay = {true}
                                    controls
                                />
                            </div>                           
                        </Modal.Body>
                        <Modal.Footer>
                        {/* <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary">Understood</Button> */}
                        </Modal.Footer>
                    </Modal>
                </div>

                <div>
                    <Modal size="lg" show={this.state.showModal} onHide={() => this.hideModal( false )}>
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm">
                                Add SMS api url
                            </Modal.Title>

                        </Modal.Header>
                        <Modal.Body>
                            <div>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>DTMF 1</Form.Label>
                                <Form.Check type="checkbox" custom inline className="float-left mr-0" checked={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_1`]['enabled']} name={`${this.state.apiModalelemId}_1`} onChange={this.onApiUrlCheckEnable} />
                                <div className={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_1`]['enabled'] ? '' : "d-none"}>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Label className="font-weight-bold">Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125;</Form.Label>
                                        <Form.Control as="textarea" rows={3} value={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_1`]['sms_url']} name={`${this.state.apiModalelemId}_1`} onChange={this.onApiUrlUpdate} />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Label className="font-weight-bold">Note: Please Paste the SMS text here</Form.Label>
                                        <Form.Control as="textarea" rows={3} value={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_1`]['sms_text']} name={`${this.state.apiModalelemId}_1`} onChange={this.onSmsTextUpdate} />
                                    </Col>
                                </Row>
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                <Form.Label>DTMF 2</Form.Label>
                                <Form.Check type="checkbox" custom inline className="float-left mr-0" checked={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_2`].enabled} name={`${this.state.apiModalelemId}_2`} onChange={this.onApiUrlCheckEnable} />
                                <div className={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_2`]['enabled'] ? '' : "d-none"}>
                                <Row>
                                    <Col sm={6}>    
                                        <Form.Label className="font-weight-bold">Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125;</Form.Label>
                                        <Form.Control as="textarea" rows={3} value={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_2`].sms_url} name={`${this.state.apiModalelemId}_2`} onChange={this.onApiUrlUpdate} />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Label className="font-weight-bold">Note: Please Paste the SMS text here</Form.Label>
                                        <Form.Control as="textarea" rows={3} value={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_2`].sms_text} name={`${this.state.apiModalelemId}_2`} onChange={this.onSmsTextUpdate} />
                                    </Col>
                                </Row>
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea3">
                                <Form.Label>DTMF 3</Form.Label>
                                <Form.Check type="checkbox" custom inline className="float-left mr-0" checked={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_3`].enabled} name={`${this.state.apiModalelemId}_3`} onChange={this.onApiUrlCheckEnable} />
                                <div className={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_3`]['enabled'] ? '' : "d-none"}>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Label className="font-weight-bold">Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125;</Form.Label>
                                        <Form.Control as="textarea" rows={3} value={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_3`].sms_url} name={`${this.state.apiModalelemId}_3`} onChange={this.onApiUrlUpdate} />
                                    
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Label className="font-weight-bold">Note: Please Paste the SMS text here</Form.Label>
                                        <Form.Control as="textarea" rows={3} value={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_3`].sms_text} name={`${this.state.apiModalelemId}_3`} onChange={this.onSmsTextUpdate} />
                                    
                                    </Col>
                                </Row>
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea4">
                                <Form.Label>DTMF 4</Form.Label>
                                <Form.Check type="checkbox" custom inline className="float-left mr-0" checked={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_4`].enabled} name={`${this.state.apiModalelemId}_4`} onChange={this.onApiUrlCheckEnable} />
                                <div className={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_4`]['enabled'] ? '' : "d-none"}>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Label className="font-weight-bold">Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125;</Form.Label>
                                        <Form.Control as="textarea" rows={3} value={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_4`].sms_url} name={`${this.state.apiModalelemId}_4`} onChange={this.onApiUrlUpdate} />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Label className="font-weight-bold">Note: Please Paste the SMS text here</Form.Label>
                                        <Form.Control as="textarea" rows={3} value={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_4`].sms_text} name={`${this.state.apiModalelemId}_4`} onChange={this.onSmsTextUpdate} />
                                    </Col>
                                </Row>
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea5">
                                <Form.Label>DTMF 5</Form.Label>
                                <Form.Check type="checkbox" custom inline className="float-left mr-0" checked={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_5`].enabled} name={`${this.state.apiModalelemId}_5`} onChange={this.onApiUrlCheckEnable} />
                                <div className={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_5`]['enabled'] ? '' : "d-none"}>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Label className="font-weight-bold">Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125;</Form.Label>
                                        <Form.Control as="textarea" rows={3} value={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_5`].sms_url} name={`${this.state.apiModalelemId}_5`} onChange={this.onApiUrlUpdate} />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Label className="font-weight-bold">Note: Please Paste the SMS text here</Form.Label>
                                        <Form.Control as="textarea" rows={3} value={this.state.NodeDataApiUrl[`${this.state.apiModalelemId}_5`].sms_text} name={`${this.state.apiModalelemId}_5`} onChange={this.onSmsTextUpdate} />
                                    </Col>
                                    </Row>
                                </div>
                            </Form.Group>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                </Card.Body>
				</Card>
            </div>

           </React.Fragment>
      );
   }
}

export default AddIVR;