import React, { Component } from 'react';
import { Button, Modal, Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import ToggleButton from 'react-toggle-button'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import "./index.css";
class ListVoiceBlacklist extends Component {
	constructor(props) {
		super(props);
		this.state = {
			blacklist: [],
			confirmDelete: false,
			selectedBlId: 0
		}
	}
	componentDidMount() {
		this.getBlacklist();
	}

	getBlacklist = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/blacklist/'+userId)
			.then(res => {
				this.setState({
					blacklist: res,
					confirmDelete: false,
				})
			})
			.catch(err => { console.log('Error in fetching Voice Files', err) });
	}

	confirmDelete = (status, blId) => {
		this.setState({
			confirmDelete: status,
			selectedBlId: blId
		});
	}

	deleteBlacklist = () => {
		const blacklistId = this.state.selectedBlId;
		const data = {

		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/blacklist/' + blacklistId, { headers: { "Content-type": "application/json" }, method: 'DELETE', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getBlacklist();
			})
			.catch(err => {
				console.log('Error in Deleting Blacklist Number', err);
			});
	}

	render() {

		const blacklist = this.state.blacklist;
		let columns = '';
		
			columns = [
			{
				dataField: 'blacklistId',
				text: 'Blacklist Id.',
				sort: true,
				hidden: false
			},
			{
				dataField: 'mobile',
				text: 'Mobile',
				sort: true,
				searchable: true
			}, {
				dataField: 'reqDate',
				text: 'Request Date',
				sort: false,
				searchable: false
			}, {
				dataField: 'action',
				text: 'Action',
				sort: false,
				searchable: false
			},];

		const products = Array.isArray(blacklist) ? blacklist.map((blist, index) => {
			
			let action = [];
			action.push(<a data-tip="Delete Blacklist" key={`${index}-delete-bl`} className="delete-bl" onClick={(e) => { this.confirmDelete(true, blist.blacklistId) }} href="#"><i className="fa fa-trash"></i></a>)
			return {
				blacklistId: blist.blacklistId,
				mobile: blist.mobile,
				reqDate: blist.reqDate,
				action: action
			};
		}) : []
		const { SearchBar, ClearSearchButton } = Search;

		const defaultSorted = [{
			dataField: 'blacklistId',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: products.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};

		return (
			<Card>
				<Card.Header>Blacklist</Card.Header>
				<Card.Body>
					<div className="campaign-list-table-cont">
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (<ToolkitProvider keyField="blacklistId" data={products} columns={columns} search bootstrap4>
									{props => (<div className="">
										Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
										<span className="search-cont"><SearchBar {...props.searchProps} /></span>
										<ClearSearchButton className="btn-primary" {...props.searchProps} />
										<hr />
										<BootstrapTable bordered={true} hover condensed defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="blacklist-table" {...props.baseProps} {...paginationTableProps} />
										<PaginationListStandalone {...paginationProps} />
									</div>)}
								</ToolkitProvider>)
							}
						</PaginationProvider>}
					</div>
					<Modal size="md" show={this.state.confirmDelete} onHide={() => this.confirmDelete(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Delete Blacklist
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to delete this number from balcklist?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmDelete(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.deleteBlacklist} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>
				</Card.Body>
			</Card>
		);
	}
}


export default ListVoiceBlacklist;