import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { Button, Modal, Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import "./index.css";
class ListVoiceFiles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			voicelist: [],
			confirmDelete: false,
			confirmApprove: false,
			confirmReject: false,
			selectedPromptId: 0,
			selectedPromptName: '',
			customerId: ''
		}
	}
	componentDidMount() {
		this.getPrompts();
	}

	getPrompts = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		if(this.props.app.role === 'ROLE_SADMIN')
		{
			Fetcher.get(apiPath + '/api/obd/prompts/list')
			.then(res => {
				this.setState({
					voicelist: res,
					confirmDelete: false,
					confirmApprove: false,
					confirmReject: false,
				})
			})
			.catch(err => { console.log('Error in fetching Voice Files', err) });
		}
		else
		{
			Fetcher.get(apiPath + '/api/obd/prompts/' + userId)
			.then(res => {
				this.setState({
					voicelist: res,
					confirmDelete: false,
					confirmApprove: false,
					confirmReject: false,
				})
			})
			.catch(err => { console.log('Error in fetching Voice Files', err) });
		}
	}

	showListenModal = (promptName, customerId) => {
		this.setState( {
			showListenModal: true,
			modalHeading: 'Listen Voice File',
			modalContent: <ReactPlayer url={`${this.props.app.apiPath}/assets/voice/{${customerId}}/prompts/{${promptName}}`} playing />,
			selectedPromptName: promptName,
			customerId: customerId,
			copied: false
		} )
	}

	downloadVoiceFile = (promptName, customerId) => {
		window.open(this.props.app.apiPath + '/assets/voice/' + customerId + '/prompts/' + promptName, 'Download');
	}

	confirmDelete = (status, promptId) => {
		this.setState({
			confirmDelete: status,
			confirmApprove: false,
			confirmReject: false,
			showListenModal: false,
			selectedPromptId: promptId
		});
	}

	confirmApprove = (status, promptId) => {
		this.setState({
			confirmApprove: status,
			confirmDelete: false,
			confirmReject: false,
			showListenModal: false,
			selectedPromptId: promptId
		});
	}

	confirmReject = (status, promptId) => {
		this.setState({
			confirmReject: status,
			confirmApprove: false,
			confirmDelete: false,
			showListenModal: false,
			selectedPromptId: promptId
		});
	}

	deletePrompt = () => {
		const promptId = this.state.selectedPromptId;
		const data = {

		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/prompt/' + promptId, { headers: { "Content-type": "application/json" }, method: 'DELETE', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getPrompts();
			})
			.catch(err => {
				console.log('Error in Deleting Voice File', err);
			});
	}

	approvePrompt = () => {
		const promptId = this.state.selectedPromptId;
		const data = {

		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/prompt/approve/' + promptId, { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getPrompts();
			})
			.catch(err => {
				console.log('Error in Approval', err);
			});
	}

	rejectPrompt = () => {
		const promptId = this.state.selectedPromptId;
		const data = {

		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/prompt/reject/' + promptId, { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getPrompts();
			})
			.catch(err => {
				console.log('Error in Rejecting', err);
			});
	}

	setMessageShow = ( status ) => {
		this.setState( {
			showListenModal: false,
		} );
	}

	render() {

		const prompts = this.state.voicelist;
		var columns='';
		if(this.props.app.role === 'ROLE_SADMIN')
		{
			columns = [
			{
				dataField: 'promptId',
				text: 'Voice File Id',
				sort: true,
				hidden: false
			},
			{
				dataField: 'name',
				text: 'File Name',
				sort: true,
				searchable: true
			}, {
				dataField: 'promptCategory',
				text: 'File Type',
				sort: false,
				searchable: false
			}, {
				dataField: 'promptStatus',
				text: 'Status',
				sort: false,
				searchable: false
			},
			{
				dataField: 'username',
				text: 'Uploaded By',
				sort: true,
				searchable: true
			},
			{
				dataField: 'accountType',
				text: 'Account',
				sort: false,
				searchable: false
			},
			{
				dataField: 'uploadTime',
				text: 'Upload Time',
				sort: false,
				searchable: false
			},
			{
				dataField: 'approvalTime',
				text: 'Approval Time',
				sort: false,
				searchable: false
			},
			{
				dataField: 'action',
				text: 'Action',
				sort: false,
				searchable: false
			},];
		}
		else if(this.props.app.role === 'ROLE_ADMIN')
		{
			columns = [
			{
				dataField: 'promptId',
				text: 'Voice File Id',
				sort: true,
				hidden: false
			},
			{
				dataField: 'name',
				text: 'File Name',
				sort: true,
				searchable: true
			}, {
				dataField: 'promptCategory',
				text: 'File Type',
				sort: false,
				searchable: false
			}, {
				dataField: 'promptStatus',
				text: 'Status',
				sort: false,
				searchable: false
			},
			{
				dataField: 'username',
				text: 'Uploaded By',
				sort: true,
				searchable: true
			},
			{
				dataField: 'accountType',
				text: 'Account',
				sort: false,
				searchable: false
			},
			{
				dataField: 'action',
				text: 'Action',
				sort: false,
				searchable: false
			},];
		}
		else
		{
			columns = [
				{
					dataField: 'promptId',
					text: 'Voice File Id',
					sort: true,
					hidden: false
				},
				{
					dataField: 'name',
					text: 'File Name',
					sort: true,
					searchable: true
				}, {
					dataField: 'promptCategory',
					text: 'File Type',
					sort: false,
					searchable: false
				}, {
					dataField: 'promptStatus',
					text: 'Status',
					sort: false,
					searchable: false
				},
				{
					dataField: 'accountType',
					text: 'Account',
					sort: false,
					searchable: false
				},
				{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false
				},];
		}

		const products = Array.isArray(prompts) ? prompts.map((prompt, index) => {
			var status='';
			switch ( prompt.promptStatus ) {
				case 0:
					status = 'Pending';
					break;
				case 1:
					status = 'Approved';
					break;
				case 2:
					status = 'Rejected';
					break;
				default:
					status = '';
					break;
			}
			var account='';
			switch ( prompt.accountType ) {
				case 0:
					account = 'Promotional';
					break;
				case 1:
					account = 'Transactional';
					break;
				default:
					account = '';
					break;
			}
			let action = [];
			
			if(this.props.app.role === 'ROLE_SADMIN')
			{
				if((prompt.promptStatus === 0) && (prompt.accountType === 1 || prompt.accountType === 0))
				{
					action = [<a key={`${index}-approve-file`} className="btn-sm btn btn-default btn-success" onClick={(e) => { this.confirmApprove(true, prompt.promptId) }} href="#">Approve</a>];
					action.push(<a key={`${index}-reject-file`} className="btn-sm btn btn-default btn-danger" onClick={(e) => { this.confirmReject(true, prompt.promptId) }} href="#">Reject</a>);
					action.push(<a key={`prompt1-listen-${index}`} className=" btn-sm btn btn-default btn-primary" onClick={(e) => { this.showListenModal(prompt.fileName, prompt.userId) }} href="#">Listen</a>);
				}
				else
				{
					action = [<a key={`prompt-${index}`} className=" btn-sm btn btn-default btn-primary" onClick={(e) => { this.showListenModal(prompt.fileName, prompt.userId) }} href="#">Listen</a>];
				}
				return {
					promptId: prompt.promptId,
					name: prompt.fileName,
					promptCategory: prompt.promptCategory,
					promptStatus: status,
					accountType: account,
					username: prompt.username,
					action: action,
					uploadTime: prompt.uploadTime,
					approvalTime: prompt.approvalTime,
				};
			}
			else if(this.props.app.role === 'ROLE_ADMIN')
			{
				action = [<a key={`prompt-listen-${index}`} className=" btn-sm btn btn-default btn-success" onClick={(e) => { this.showListenModal(prompt.fileName, prompt.userId) }} href="#">Listen</a>];
				action.push(<a key={`prompt-download-${index}`} className=" btn-sm btn btn-default btn-primary" onClick={(e) => { this.downloadVoiceFile(prompt.fileName, prompt.userId) }} href="#">Download</a>);
				return {
					promptId: prompt.promptId,
					name: prompt.fileName,
					promptCategory: prompt.promptCategory,
					promptStatus: status,
					accountType: account,
					username: prompt.username,
					action: action
				};
			}
			else
			{
				action = [<a data-tip="Delete File" key={`${index}-delete-file`} className="btn-sm btn btn-default btn-danger" onClick={(e) => { this.confirmDelete(true, prompt.promptId) }} href="#">Delete</a>];
				action.push(<a key={`prompt-listen-${index}`} className=" btn-sm btn btn-default btn-success" onClick={(e) => { this.showListenModal(prompt.fileName, prompt.userId) }} href="#">Listen</a>);
				action.push(<a key={`prompt-download-${index}`} className=" btn-sm btn btn-default btn-primary" onClick={(e) => { this.downloadVoiceFile(prompt.fileName, prompt.userId) }} href="#">Download</a>);
				return {
					promptId: prompt.promptId,
					name: prompt.fileName,
					promptCategory: prompt.promptCategory,
					promptStatus: status,
					accountType: account,
					action: action
				};
			}
			
		}) : []
		const { SearchBar, ClearSearchButton } = Search;

		const defaultSorted = [{
			dataField: 'promptId',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: products.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};

		return (
			<Card>
				<Card.Header>Voice Files</Card.Header>
				<Card.Body>
					<div className="campaign-list-table-cont">
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (<ToolkitProvider keyField="id" data={products} columns={columns} search bootstrap4>
									{props => (<div className="">
										Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
										<span className="search-cont"><SearchBar {...props.searchProps} /></span>
										<ClearSearchButton className="btn-primary" {...props.searchProps} />
										<hr />
										<BootstrapTable bordered={true} condensed defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="voicelist-table" {...props.baseProps} {...paginationTableProps} />
										<PaginationListStandalone {...paginationProps} />
									</div>)}
								</ToolkitProvider>)
							}
						</PaginationProvider>}
					</div>
					<Modal size="md" show={this.state.confirmDelete} onHide={() => this.confirmDelete(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Delete Voice File
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to delete this file?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmDelete(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.deletePrompt} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>
					<Modal size="md" show={this.state.confirmApprove} onHide={() => this.confirmApprove(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Approve Voice File
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to approve this file?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmApprove(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.approvePrompt} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>
					<Modal size="md" show={this.state.confirmReject} onHide={() => this.confirmReject(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Reject Voice File
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to reject this file?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmReject(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.rejectPrompt} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>
					<Modal size="lg" show={this.state.showListenModal} onHide={() => this.setMessageShow( false )}>
						<Modal.Header closeButton>
							<Modal.Title className="modal-heading" id="example-modal-sizes-title-sm">
								{this.state.modalHeading}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body >
							<ReactPlayer url={`${this.props.app.apiPath}/assets/voice/${this.state.customerId}/prompts/${this.state.selectedPromptName}`} controls width='90%' height='80px' />
						</Modal.Body>

					</Modal>
				</Card.Body>
			</Card>
		);
	}
}


export default ListVoiceFiles;