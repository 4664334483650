import React, { Component } from 'react';
import { Button, Modal, Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import ToggleButton from 'react-toggle-button'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import "./index.css";
class ListVoicePlans extends Component {
	constructor(props) {
		super(props);
		this.state = {
			planlist: [],
			confirmDelete: false,
			selectedPlanId: 0
		}
	}
	componentDidMount() {
		this.getPlans();
	}

	getPlans = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/plans/'+userId)
			.then(res => {
				this.setState({
					planlist: res,
					confirmDelete: false
				})
			})
			.catch(err => { console.log('Error in fetching Voice Files', err) });
	}

	updatePlanStatus = ( planId, planStatus ) => {
		const status = planStatus ? 0 : 1;
		const data = {
			"planId": planId,
			"planStatus": status,
		}

		return Fetcher.post( this.props.app.apiPath + '/api/obd/plan/update/status', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify( data ) } )
			.then( res => {
				Notify.notify(res.message);
				this.getPlans();
			} )
			.catch( err => {
				console.log( 'Error in Enable/Disable', err);
				Notify.notify( 'Error in Enable/Disable');
			} );

	}

	confirmDelete = (status, planId) => {
		this.setState({
			confirmDelete: status,
			selectedPlanId: planId
		});
	}

	deletePlan = () => {
		const planId = this.state.selectedPlanId;
		const data = {

		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/plan/' + planId, { headers: { "Content-type": "application/json" }, method: 'DELETE', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getPlans();
			})
			.catch(err => {
				console.log('Error in Deleting Voice Plan', err);
				Notify.notify('Error in Deleting Voice Plan');
			});
	}

	render() {

		const plans = this.state.planlist;
		let columns = '';
		if(this.props.app.role === 'ROLE_SADMIN')
		{
			columns = [
			{
				dataField: 'planId',
				text: 'Plan Id.',
				sort: true,
				hidden: false
			},
			{
				dataField: 'planName',
				text: 'Plan Name',
				sort: true,
				searchable: true
			}, {
				dataField: 'pulseDuration',
				text: 'Pulse Duration',
				sort: false,
				searchable: false
			}, {
				dataField: 'pulsePrice',
				text: 'Pulse Price(in Paisa)',
				sort: false,
				searchable: false
			}, 
			{
				dataField: 'planStatus',
				text: 'Status',
				sort: false,
				searchable: false
			},{
				dataField: 'username',
				text: 'Created By',
				sort: false,
				searchable: false
			}, {
				dataField: 'action',
				text: 'Action',
				sort: false,
				searchable: false
			},];
		}
		else if(this.props.app.role === 'ROLE_ADMIN')
		{
			columns = [
			{
				dataField: 'planId',
				text: 'Plan Id.',
				sort: true,
				hidden: false
			},
			{
				dataField: 'planName',
				text: 'Plan Name',
				sort: true,
				searchable: true
			}, {
				dataField: 'pulseDuration',
				text: 'Pulse Duration',
				sort: false,
				searchable: false
			}, {
				dataField: 'pulsePrice',
				text: 'Pulse Price(in Paisa)',
				sort: false,
				searchable: false
			}, 
			{
				dataField: 'planStatus',
				text: 'Status',
				sort: false,
				searchable: false
			}, {
				dataField: 'action',
				text: 'Action',
				sort: false,
				searchable: false
			},];
		}

		const products = Array.isArray(plans) ? plans.map((plan, index) => {
			let status='';
			switch ( plan.planStatus ) {
				case 0:
					status = 'Disabled';
					break;
				case 1:
					status = 'Enabled';
					break;
				default:
					status = '';
					break;
			}
			/*let action = [<Link data-tip="Edit Plan" key={`${index}-edit-plan`} className="edit-plan" to={`${this.props.app.urlPrefix}/update-plan/${plan.planId}`}><i className="fa fa-edit"></i></Link>];
			action.push(<a data-tip="Delete Plan" key={`${index}-delete-plan`} className="delete-plan" onClick={(e) => { this.confirmDelete(true, plan.planId) }} href="#"><i className="fa fa-trash"></i></a>)*/
			return {
				planId: plan.planId,
				planName: plan.planName,
				pulseDuration: plan.pulseDuration,
				pulsePrice: plan.pulsePrice,
				planStatus: status,
				username: plan.username,
				action: <ToggleButton
					value={ !plan.planStatus || false}
					onToggle={( e ) => this.updatePlanStatus( plan.planId, plan.planStatus )}
					inactiveLabel=""
					activeLabel=""
					colors={{
						active: {
						  base: 'rgb(199,5,5)',
						},
						inactive: {
						  base: 'rgb(0,128,0)',
						}
					  }} />
			};
		}) : []
		const { SearchBar, ClearSearchButton } = Search;

		const defaultSorted = [{
			dataField: 'planId',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: products.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};

		return (
			<Card>
				<Card.Header>Voice Plans</Card.Header>
				<Card.Body>
					<div className="campaign-list-table-cont">
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (<ToolkitProvider keyField="planId" data={products} columns={columns} search bootstrap4>
									{props => (<div className="">
										Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
										<span className="search-cont"><SearchBar {...props.searchProps} /></span>
										<ClearSearchButton className="btn-primary" {...props.searchProps} />
										<hr />
										<BootstrapTable bordered={true} hover condensed defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="planlist-table" {...props.baseProps} {...paginationTableProps} />
										<PaginationListStandalone {...paginationProps} />
									</div>)}
								</ToolkitProvider>)
							}
						</PaginationProvider>}
					</div>
					<Modal size="md" show={this.state.confirmDelete} onHide={() => this.confirmDelete(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Delete Plan
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to delete this plan?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmDelete(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.deletePlan} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>
				</Card.Body>
			</Card>
		);
	}
}


export default ListVoicePlans;